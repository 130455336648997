const DashboardReducer = (state, action) => {
    switch (action.type) {
        case 'ORDER_SUMMARY':
            return {
                ...state,
                newOrders: action?.newOrderCount || 0,
                inProgressOrders: action?.inProgressOrderCount || 0,
                readyOrders: action?.readyOrderCount || 0,
                unfullfilledOrders: action?.unfulfilledOrderCount || 0
            };
        case 'SHORTPICK_INFO':
            return {
                ...state,
                shortPickData: action.shortPickInfo
            };
        case 'STORE_SUMMARY':
            return {
                ...state,
                enabledStores: action.enabledStores,
                disabledStores: action.disabledStores,
                neverTurnedOnStores: action.neverTurnedOnStores,
                limitExceededStores:action.limitExceededStores
            };
        case 'CREATED':
            return {
                ...state,
                createdOrdersList: action.orderData
            };
        case 'UNFULFILLED':
            return {
                ...state,
                unfullfilledOrdersList: action.orderData
            };

    }
}

const initialState = {
    newOrders: null,
    inProgressOrders: null,
    readyOrders: null,
    unfullfilledOrders: null,
    shortPickData: [],
    enabledStores: [],
    disabledStores: [],
    createdOrdersList: [],
    unfullfilledOrdersList: [],
    neverTurnedOnStores:[],
    limitExceededStores:[]
}

export { initialState, DashboardReducer }  