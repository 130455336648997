import React from 'react';
import { View, Text, Modal, TouchableOpacity, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import {SuccessIcon, ErrorIcon} from '../Icons'

const DeleteConfirmationModal = ({ isVisible, onClose, onConfirm, message, notify=false, error=false, success=false }) => {
  return (
    <Modal visible={isVisible} animationType="none" transparent={true} onRequestClose={onClose}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={styles.messageContainer}>
            {success && <SuccessIcon height={30} width={30} fill="#2ecc71"/>}
            {error && <ErrorIcon height={30} width={30} />}
            <Text>{message}</Text>
            </View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.button} onPress={onConfirm}>
                <Text style={styles.buttonText}>{!notify ? 'YES' : 'OK'}</Text>
              </TouchableOpacity>
              {!notify && <TouchableOpacity style={styles.button} onPress={onClose}>
                <Text style={styles.buttonText}>NO</Text>
              </TouchableOpacity>}
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  messageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 20,
  },
  button: {
    marginHorizontal: 10,
    paddingHorizontal: 10,
    border: '1px solid gray',
    borderRadius: 5,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
  },
});

export default DeleteConfirmationModal;
