

const sampleData2 = [
  {
    sku_id: '12345',
    product_name: 'Product A',
    upc: '1234',
    style: 'Style 1',
    color: 'Red',
    size: 'M',
    current_status: 'Available',
    exclude: false,
    // include: true,
    exclude_date: '',
    include_date: '2024-07-20',
  },
  {
    sku_id: '61230',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '17080',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '69080',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '26890',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '34890',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '17490',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '65590',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '67990',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '67824',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '67892',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
    {
    sku_id: '67894',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: 'Out of Stock',
    exclude: true,
    // include: false,
    exclude_date: '2024-07-21',
    include_date: '',
  },
];

import React, { useState, useMemo } from 'react';
import { View, Text, FlatList, StyleSheet, ScrollView, TouchableOpacity, Image, Dimensions } from 'react-native';
import { Parser } from '@json2csv/plainjs';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormPicker from './FormPicker';

const { height, width } = Dimensions.get('window');

const TableComponent = () => {
  const [data, setData] = useState(sampleData2);
  const [showDatePicker, setShowDatePicker] = useState({ type: '', index: -1 });
  const [selectAllInclude, setSelectAllInclude] = useState(false);
  const [selectAllExclude, setSelectAllExclude] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [page, setPage] = useState(0);
  const itemsPerPage = 6;

  const tableHeaders = Object.keys(data[0]);

  const sortedData = useMemo(() => {
    if (!sortColumn) return data;
    const columnIndex = tableHeaders.indexOf(sortColumn);
    return [...data].sort((a, b) => {
      if (a[tableHeaders[columnIndex]] < b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? -1 : 1;
      if (a[tableHeaders[columnIndex]] > b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, data.length);
  const numberOfPages = Math.ceil(data.length / itemsPerPage);

  const handleCheckboxChange = (index, field) => {
    const newData = [...data];
    const actualIndex = page * itemsPerPage + index;
    if (actualIndex < newData.length) {
      newData[actualIndex][field] = !newData[actualIndex][field];
      setData(newData);
    }
  };

  const handleSelectAllChange = (field, value) => {
    const newData = data.map(item => ({
      ...item,
      [field]: value
    }));
    setData(newData);
    if (field === 'include') {
      setSelectAllInclude(value);
    } else {
      setSelectAllExclude(value);
    }
  };

  const handleDateChange = (index, field, event, selectedDate) => {
    setData(prevData => {
      const actualIndex = page * itemsPerPage + index;
      const newData = [...prevData];
      if (selectedDate) {
        newData[actualIndex] = {
          ...newData[actualIndex],
          [field]: event
        };
      } else {
        newData[actualIndex] = {
          ...newData[actualIndex],
          [field]: ''
        };
      }
      return newData;
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const downloadCSV = () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(data);
    fileDownload(csv, 'tableData.csv');
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={downloadCSV} style={styles.downloadButton}>
        <Image source={require('../../assets/downloadIcon.png')} style={styles.image} />
        <Text style={styles.downloadText}>Download CSV</Text>
      </TouchableOpacity>
      <ScrollView horizontal>
        <View style={styles.table}>
          <View style={styles.header}>
            {tableHeaders.map((header, index) => (
              header !== 'exclude' && header !== 'include' ? (
                <TouchableOpacity
                  style={[styles.headerCell, styles.fixedWidth]}
                  key={index}
                  onPress={() => handleSortColumn(header)}
                >
                  <Text style={styles.headerText}>
                    {header}
                    {sortColumn === header ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : ' ↓'}
                  </Text>
                </TouchableOpacity>
              ) :
              tableHeaders.includes('exclude') ? (
                <View style={[styles.headerCell, styles.fixedWidth]} key={index}>
                  <FormPicker
                    value={selectAllExclude ? "ENABLED" : "DISABLED"}
                    options={[
                      { label: 'Select-Status', value: '' },
                      { label: 'ENABLED', value: 'ENABLED' },
                      { label: 'DISABLED', value: 'DISABLED' }
                    ]}
                    isEnabled={index <= 0}
                    onChange={(value) => handleSelectAllChange('exclude', value === 'ENABLED')}
                  />
                </View>
              ) :
              tableHeaders.includes('include') ? (
                <View style={[styles.headerCell, styles.fixedWidth]} key={index}>
                  <FormPicker
                    value={selectAllInclude ? "ENABLED" : "DISABLED"}
                    options={[
                      { label: 'Select-Status', value: '' },
                      { label: 'ENABLED', value: 'ENABLED' },
                      { label: 'DISABLED', value: 'DISABLED' }
                    ]}
                    isEnabled={index <= 0}
                    onChange={(value) => handleSelectAllChange('include', value === 'ENABLED')}
                  />
                </View>
              ) : null
            ))}
          </View>

          <FlatList
            data={sortedData.slice(from, to)}
            renderItem={({ item, index }) => (
              <View key={index} style={styles.row}>
                {tableHeaders.map((header, cellIndex) => (
                  header !== 'exclude' && header !== 'include' && header !== 'exclude_date' && header !== 'include_date' && (
                    <Text style={[styles.cell, styles.fixedWidth]} key={cellIndex}>
                      {item[header]}
                    </Text>
                  )
                ))}

                {tableHeaders.includes('exclude') && (
                  <View style={[styles.cell, styles.fixedWidth]}>
                    <FormPicker
                      value={item.exclude ? "ENABLED" : "DISABLED"}
                      options={[
                        { label: 'Select-Status', value: '' },
                        { label: 'ENABLED', value: 'ENABLED' },
                        { label: 'DISABLED', value: 'DISABLED' }
                      ]}
                      isEnabled
                      onChange={(value) => handleCheckboxChange(index, 'exclude', value === 'ENABLED')}
                    />
                  </View>
                )}

                {tableHeaders.includes('include_date') && (
                  <View style={[styles.cell, styles.fixedWidth]}>
                    <DatePicker
                      withPortal
                      portalId="root-portal-calender"
                      showYearDropdown
                      placeholderText="include date"
                      selected={item.include_date ? new Date(item.include_date) : null}
                      dateFormat="yyyy-MM-dd"
                      onChange={(event, selectedDate) => handleDateChange(index, 'include_date', event, selectedDate)}
                    />
                  </View>
                )}

                {tableHeaders.includes('exclude_date') && (
                  <View style={[styles.cell, styles.fixedWidth]}>
                    <DatePicker
                      withPortal
                      portalId="root-portal-calender"
                      showYearDropdown
                      placeholderText="exclude Date"
                      selected={item.exclude_date ? new Date(item.exclude_date) : null}
                      dateFormat="yyyy-MM-dd"
                      onChange={(event, selectedDate) => handleDateChange(index, 'exclude_date', event, selectedDate)}
                    />
                  </View>
                )}
              </View>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </ScrollView>

      {data.length > itemsPerPage && (
        <View style={styles.paginationFooter}>
          <TouchableOpacity
            style={styles.paginationButton}
            disabled={page === 0}
            onPress={() => setPage(page - 1)}
          >
            <Text>{`< Previous`}</Text>
          </TouchableOpacity>
          <Text>{page + 1} of {numberOfPages}</Text>
          <TouchableOpacity
            style={styles.paginationButton}
            disabled={page === numberOfPages - 1}
            onPress={() => setPage(page + 1)}
          >
            <Text>{`Next >`}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20
  },
  downloadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  image: {
    width: 20,
    height: 20
  },
  downloadText: {
    marginLeft: 10,
    fontSize: 16
  },
  table: {
    borderWidth: 1,
    borderColor: '#ccc',
    backgroundColor: "#fff"
  },
  header: {
    flexDirection: 'row',
    backgroundColor: "#fff"
  },
  headerCell: {
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerText: {
    fontWeight: 'bold'
  },
  row: {
    flexDirection: 'row'
  },
  cell: {
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc'
  },
  fixedWidth: {
    width: width /4
  },
  paginationFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10
  },
  paginationButton: {
    padding: 10
  }
});

export default TableComponent;


