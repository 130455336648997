import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const NotesSection = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Notes:</Text>

      <View style={styles.noteBox}>
        <Text style={styles.title}>Immediate Update:</Text>
        <Text style={styles.description}>
          To ensure that the exclusion/inclusion flag takes effect immediately, please only update the "Change Status" column in the table below. Do not update the "Include Date" or "Exclude Date" columns. Please leave both of these columns blank.
        </Text>
      </View>

      <View style={styles.noteBox}>
        <Text style={styles.title}>Future Update:</Text>
        <Text style={styles.description}>
          For all future updates, please only update the "Include Date" or "Exclude Date" columns. Both "Include Date" and "Exclude Date" can be updated at once. Do not update the "Change Status" column. Please leave it blank.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    paddingHorizontal: 15,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
  },
  noteBox: {
    marginBottom: 15,
    padding: 15,
    borderRadius: 8,
    backgroundColor: '#f9f9f9',
    borderWidth: 1,
    borderColor: '#ddd',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
    color: '#000000',
  },
  description: {
    fontSize: 16,
    color: '#555',
    lineHeight: 22,
  },
});

export default NotesSection;
