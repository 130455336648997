import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const FieldSet = ({ title, children, customStyle }) => {
    return (
        <View style={{...styles.container}}>
            <Text style={styles.title}>{title}</Text>
            <View style={{...styles.content, ...customStyle}}>{children}</View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 5,
    },
    title: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: '#f2f2f0',
        paddingHorizontal: 10,
        fontWeight: 'bold',
    },
    content: {
        display: "flex",
        padding: 10,
        marginTop:10,
        flexDirection: "column",
        gap: 10,
    },
});

export default FieldSet;
