import {useState, useContext} from 'react';
import { Modal,Button, StyleSheet, Text, TextInput, View } from 'react-native';
import AuthContext from '../hooks/AuthContext';
import TextField from '../components/TextField';

function SignInScreen() {

  const [signInDetails, setSignInDetails] = useState({
    username: '',
    password: ''
  })
  const [errorFields, setErrorFields] = useState({
    username: false,
    password: false
  })
  const { signIn, globalError } = useContext(AuthContext);

  const handleSignInPress = () => {
    const {username, password} = signInDetails;
    if(username && password) {
      signIn({username, password}) // signIn logic
    }
    else {
      setErrorFields((prev) => ({...prev, username: true, password: true}))
    }
  };

  const handlePasswordKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      handleSignInPress();
    }
  };

  const onChangeHandler = (key,value) => {
    if(value === ''){
      setErrorFields((prev) => ({...prev, [key]: true}))
    }
    else {
      setErrorFields((prev) => ({...prev, [key]: false}))
    }
    setSignInDetails((prev) => ({...prev, [key]: value}))
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={true} >

      <View style={styles.container}>
      {(Object.values(errorFields).some(fieldError => fieldError) || globalError ) && <Text style={{ color: 'red', marginBottom: 10 }}>{globalError?.message || 'Please provide credentials' }</Text>}
        <Text style={styles.icon}>👤</Text>
        <TextField
          placeholder="Username"
          value={signInDetails.username}
          onChangeText={(value) => onChangeHandler('username', value)}
          required
          error={errorFields.username}
        />
        <TextField
          placeholder="Password"
          value={signInDetails.password}
          onChangeText={(value) => onChangeHandler('password', value)}
          required
          error={errorFields.password}
          secureTextEntry
          onKeyPress={handlePasswordKeyPress}
        />
        <View style={styles.buttonWrapper}/>
        <Button title="Sign in" onPress={handleSignInPress} />
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#25292e', // Modal background color
    },
    icon: {
      fontSize: 50, // emoji size
      marginBottom: 20, // space between the icon and the first text field
    },
    buttonWrapper: {
      marginBottom: 20, // This adds space below the TextFields
    }
  });
export default SignInScreen;
