const moment = require('moment-timezone'); 

// Output Format 
function convertTimeToDate(timeString, timeZone='America/Chicago') {
  const dateTimeString = `2000-01-01 ${timeString}`;
  const date = moment.tz(dateTimeString, 'YYYY-MM-DD hh:mm A', timeZone).toDate();
  return date;
}

// Output Format 10:00 AM/PM
function formatTimeFromDate(date, timeZone='America/Chicago') {
  const formattedTime = moment.tz(date, timeZone).format('hh:mm A');
  return formattedTime;
}

// Format date as "MM/DD/YYYY"
const formatDate = (isoDate) => {
  const parsedDate = new Date(isoDate);
  const year = parsedDate.getUTCFullYear();
  const month = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = String(parsedDate.getDate()).padStart(2, '0');
  
  return isoDate ? `${month}/${day}/${year}` : isoDate;
};


// Format time as "h:mm A"
const formatTime = (isoTime) => {
  const parsedDate = new Date(isoTime);
  const hours = parsedDate.getUTCHours();
  const minutes = parsedDate.getUTCMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  
  return isoTime ? `${formattedHours}:${formattedMinutes} ${period}` : isoTime;
};



export {formatDate, formatTime, convertTimeToDate, formatTimeFromDate}