import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content';


const DashboardCard = ({ orderCount, content }) => {
    return (
        <View style={styles.cardContainer}>
            <SkeletonContent
                containerStyle={{ flex: 1 }}
                isLoading={orderCount == null}
                layout={[
                    { key: 'someId', width: 70, height: 30, marginBottom: 6, alignSelf: "center", marginLeft: -20 },
                    { key: 'someOtherId', width: 180, height: 25, marginBottom: 6, alignSelf: "center", marginLeft: -20 }
                ]}
            >
                <Text style={styles.cardCount}>{orderCount}</Text>
                <Text style={styles.cardText}>{content}</Text>
            </SkeletonContent>
        </View>
    )
}


const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
        padding: 20,
        backgroundColor: "white",
        borderRadius: 12,
        // Shadow properties for iOS
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        // Shadow properties for Android
        elevation: 5,
    },
    cardCount: {
        textAlign: 'center',
        fontSize: 35,
        fontWeight: "bold"
    },
    cardText: {
        textAlign: 'center',
    }
})


export default DashboardCard