import React, { useState, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { useWindowDimensions } from 'react-native';
import LoadingOverlay from '../components/LoadingOverlay';
const OrderStats = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const { width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://store-fulfillments.alo.technology/statistics/orders/dropped/2bac13e7-6a34-4b76-b9a1-82536872e99d')
            .then(response => response.text())
            .then(html => {
                setHtmlContent(html);
                setIsLoading(false); // Set loading to false when content is loaded
            })
            .catch(error => {
                console.error('Failed to load HTML content:', error);
                setIsLoading(false); // Set loading to false on error as well
            });
    }, []);


    return (
        <ScrollView style={{ flex: 1 }}>
            {isLoading ? ( // Render loader if still loading
                <View style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><LoadingOverlay /></View>
            ) : (
                <>
                    <style>
                        {`
          .html-content {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          h2 {
            align-self: start;
            width: 100%; /* Makes sure the heading spans the full width of the container */
            background-color: #f0f0f0; /* Light background for the heading */
            padding: 10px 0;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 0; /* Removes margin below the heading */
          }
          table {
            width: 100%; /* Adjust based on your preference */
            max-width: 500px; /* Limits table width on larger screens */
            margin-top: 10px; /* Space between heading and table */
            border-collapse: collapse;
            background-color: #f9f9f9; /* Light background for the table */
          }
          th, td {
            border: 1px solid #ddd; /* Lighter border color */
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f4f4f4; /* Distinguish headers with a different color */
            font-weight: bold;
          }
          @media (max-width: 600px) {
            .html-content {
              flex-direction: column;
              margin:10px;
            }
            table, h2 {
              width: 100%;
            }
          }
        `}
                    </style>

                    <div className="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </>
            )
            }
        </ScrollView >
    );
};

export default OrderStats;
