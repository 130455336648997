import React, { useState, useEffect, useContext } from 'react';
import { View, Text, FlatList,StyleSheet, TouchableOpacity,Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import IconButton from '../components/IconButton';
import useAuthenticatedFetch from '../hooks/useAuthenticatedRequest';
import AuthContext from '../hooks/AuthContext';
import {formatDate} from "../utils/formatDate";
import LoadingOverlay from '../components/LoadingOverlay';
import Notification from '../components/Notification';
import NoDataMessage from '../components/NoDataMessage';
import { DeleteIcon } from '../Icons';
import ConfirmationModal from '../components/ConfirmationModal'
import UserModal from '../components/UserModal'
import GenericModal from '../components/Modal';

const StoreItem = ({ item, confirmHandler, role }) => {
  const navigation = useNavigation();
  const [isHovered, setIsHovered] = useState(false);
  const {basic: store, location: address, regular_hours:hours, special_hours:specialHours} = item;
  const rowStyle = isHovered ? styles.rowHovered : styles.row;
  const [modalVisibility, setModalVisibility] = useState(false);
  const isDatePast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to midnight for comparison
    const specialDate = new Date(date);
    specialDate.setHours(0, 0, 0, 0); // Set the time to midnight for comparison

    return specialDate < today;
  };
  return (
    <View>
    <Pressable
      onPressIn={() => setIsHovered(true)}
      onPressOut={() => setIsHovered(false)}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPress={() => {
        (role === 'admin' || role === 'manager') && navigation.navigate('Store', { storeData: item, isEdit:true })
      }}
    >
      <View style={rowStyle}>
        <View style={styles.column}>
                <Text style={styles.header}>{store.store_number} {store.store_name}</Text>
                {/* <Text>{address.address_line1}, {address.city}, {address.state}, {address.zipcode}</Text> */}
                <Text>{`${address.address_line1}, ${address.city}, ${address?.state ? address.state+",":''}${address.zipcode}`}</Text>
                <Text>{address.country}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.header}>Regular Hours</Text>
                {Object.entries(hours).map(([day, dayHours]) => (
                <Text key={day}>{day}: {dayHours.open_at} - {dayHours.close_at}</Text>
                ))}
            </View> 
            <View style={styles.column}>  
                <Text style={styles.header}>Special Hours</Text>
                {specialHours.map((specialHour, index) => (
                !isDatePast(specialHour.date) && <Text key={index}>{formatDate(specialHour.date)} {specialHour.open_at} - {specialHour.close_at} {specialHour.reason}</Text>
                ))}
          </View>
              {role === 'admin' && <TouchableOpacity onPress={() => {setModalVisibility(true)}}>
                <DeleteIcon height={24} width={24}/>
              </TouchableOpacity>}
      </View>
    </Pressable>
        <ConfirmationModal
              isVisible={modalVisibility}
              message='Are you sure you want to delete?'
              onClose={() => {setModalVisibility(false)}}
              onConfirm={() => confirmHandler(store?.store_id, store?.geo_hash)}
            />
    </View>
  );
};


const Stores = ({route}) => {
  const isFocused = useIsFocused();
  const {modalOpen, setModalOpen, userName, role} = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1); // For Pagination
  const [itemsPerPage, setItemsPerPage] = useState(5); // For Pagination
  const [direction, setDirection] = useState(null);  // For Pagination
  const [pageKeys, setPageKeys] = useState([]); // For Pagination

  const [nextKey, setNextKey] = useState(null);
  const [geoHashKey, setGeoHashKey] = useState(null);
  const [lastUpdatedKey, setLastUpdatedKey] = useState(null);
  const [stores, setStores] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userAction, setUserAction] = useState('');
  const [deleted, setDeleted] = useState(null);
  const {params} = route

const { isLoading, error, data, triggerNetworkCall } = useAuthenticatedFetch('/api/stores', 'GET');
const { isLoading: userLoading, error: userError, data: userData, triggerNetworkCall: userCall } = useAuthenticatedFetch('/api/user', 'POST');
const { isLoading: deleteLoading, error:deleteError, data: deleteData, triggerNetworkCall: deleteCall } = useAuthenticatedFetch('/api/store', 'DELETE');

 const confirmHandler = async (storeId, hashId) => {
    await deleteCall(null, `/${storeId}/${hashId}`);
  }

useEffect(() => {
    if(userError || userData || deleteData || deleteError) {
      setConfirm(true)
    }
  }, [userError, userData, deleteError, deleteData]);


useEffect(() => {
    let urlParams = `?page=${currentPage}&limit=${itemsPerPage}`;
    if (direction === 'forward' && nextKey && geoHashKey && lastUpdatedKey) {
      urlParams += `&startKey=${encodeURIComponent(nextKey)}
          &geoHashKey=${encodeURIComponent(geoHashKey)}
          &lastUpdatedKey=${encodeURIComponent(lastUpdatedKey)}`;
    } else if (direction === 'backward' && currentPage > 1) {
      const prevPageKey = pageKeys[currentPage - 2];
      if (prevPageKey) {
        urlParams += `&startKey=${encodeURIComponent(prevPageKey.nextKey)}
            &geoHashKey=${encodeURIComponent(prevPageKey.geoHashKey)}
            &lastUpdatedKey=${encodeURIComponent(prevPageKey.lastUpdatedKey)}`;
      }
    }
    triggerNetworkCall(null, urlParams);
  }, [currentPage, itemsPerPage, route, deleted, direction]);

    useEffect(() => {
    if (!isFocused && params) {
      setNextKey(null);
      setGeoHashKey(null);
      setLastUpdatedKey(null);
    }
  }, [isFocused]);

  useEffect(() => {
    if (data && data.stores && Array.isArray(data.stores)) {
      setStores(data.stores);
      if (direction === 'forward') {
        setPageKeys([...pageKeys, { nextKey: nextKey, geoHashKey: geoHashKey, lastUpdatedKey: lastUpdatedKey }]);
      }
      setNextKey(data?.nextKey || null);
      setGeoHashKey(data?.nextGeoHash || null)
      setLastUpdatedKey(data?.nextLastUpdated || null)
    }
  }, [data, itemsPerPage, route]);

  const renderItem = ({ item }) => {
    return <StoreItem item={item} confirmHandler={confirmHandler} role={role}  />;
  }

  return (
    (isLoading || userLoading || deleteLoading) ? <LoadingOverlay /> :
    (stores.length > 0 ? (<View>
      <FlatList
        data={stores}
        renderItem={renderItem}
        keyExtractor={(item) => item.basic.store_number+item.basic.store_id}
      />
      {error && <Notification type="error" message={error.message || "Error in fetching stores!!"}  />}
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <IconButton 
          icon="arrow-back" 
          label="Back" 
          onPress={() => {
            setDirection('backward');
            setCurrentPage(currentPage - 1);
          }} 
          disabled={currentPage === 1}
        />
        <IconButton 
          icon="arrow-forward" 
          label="Next" 
          onPress={() => {
            setDirection('forward');
            setCurrentPage(currentPage + 1);
          }} 
          disabled={!nextKey}
        />
      </View>
      <GenericModal isVisible={modalOpen} onClose={() => {setModalOpen(false)}} onCreateUser={() => {
          setUserAction('newUser');
          setModalOpen(false);
          setUserModalOpen(true)
      }} onChangePassword={() => {
          setUserAction('password');
          setModalOpen(false);
          setUserModalOpen(true)
      }} role={role} />
      <UserModal isVisible={userModalOpen} selectedOption={userAction} onClose={() => {setUserModalOpen(false)}} onCreateUser={async (payload) => {
          await userCall(payload);
          setUserModalOpen(false)
      }} onChangePassword={async (payload) => {
          await userCall(payload);
          setUserModalOpen(false)
      }} />
      <ConfirmationModal
        isVisible={confirm}
        message={(userError || deleteError) ? 'Something went wrong! Please try again' : ( userData ? 'User Details saved successfully!' : 'Store deleted successfully !' )}
        onClose={() => {
          setConfirm(false);
        }}
        onConfirm={() => {
          setConfirm(false);
          deleteData && setDeleted(deleteData);
        }}
        notify
        {...(userError ? { error: true } : { success: true })}
      />
    </View>) : <NoDataMessage />)
  );
};


const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      backgroundColor: '#f8f8f8',
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#ddd',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    rowHovered: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      backgroundColor: '#e8e8e8',
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#ddd',
    },
    searchBar: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      paddingLeft: 10,
      marginBottom: 10
    },
    column: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      marginBottom: 5,
    },
  });

export default Stores;
