import React from "react";
import { View, Text, StyleSheet } from "react-native";
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import TimePicker from "./TimePicker";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const RegularHours = (props) => {
  const handleHourChange = (day, period, hour) => {
   props.setHours('regular_hours', day, hour, period)
  }

  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.header1}>Day</Text>
        <View style={styles.row1}>
          <Text style={styles.header}>Open</Text>
          <Text style={styles.header}>Close</Text>
        </View>
      </View>
      {daysOfWeek.map(day => (
        <View key={day} style={styles.row}>
          <Text style={styles.cell}>{day}</Text>
          <View style={styles.picker}>
            <TimePicker selected={props.hours[day]?.open_at} heading="Opening time" placeholder="Opening time" handleChange={(datetime) => handleHourChange(day, 'open_at', datetime)}/>
            <TimePicker selected={props.hours[day]?.close_at} heading="Closing time" placeholder="Closing time" handleChange={(datetime) => handleHourChange(day, 'close_at', datetime)}/>
          </View>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  row1: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 2,
  },
  cell: {
    flex: 1,
    height: 30,
    marginLeft: 10,
    height: '40px',
     borderRadius: '5px',
      padding: '5px'
  },
  header: {
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  header1 : {
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
    flex:1
  },
  picker: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
});

export default RegularHours;
