import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { FileDownload } from '../components/FileDownload'
import FileUpload from './FileUpload'
import { SearchSku } from '../components/SearchSku'


export const FileUploadDownload = () => {

    return (
        <View>
            <Text style={styles.header}>Download File</Text>
            <FileDownload />
            <Text style={styles.header}>Upload File</Text>
            <FileUpload />
            <Text style={styles.header}>Search and Edit </Text>
            <SearchSku />
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        fontSize: 25,
        marginHorizontal: 30,
        marginVertical: 20,
        fontWeight: "500",
    }
});