import React, {forwardRef} from 'react';
import { StyleSheet, TextInput, View, Text, ActivityIndicator } from 'react-native';

const FormInput = forwardRef(({value,error=null, placeholder, onChangeText,loading=false, isEditable=true,focusHandler, ...args }, ref) => {
  return (
    <View ref={ref}>
      <View style={styles.container}>
          {isEditable &&  <TextInput 
            value={value}
            style={error ? {...styles.input, borderColor: "red"} : {...styles.input} } 
            placeholder={placeholder}
            onChangeText={onChangeText}
            onFocus={focusHandler}
            {...args}
            />}
          {!isEditable &&  <TextInput 
            value={value}
            style={error ? {...styles.input, borderColor: "red"} : {...styles.nonedit} } 
            placeholder={placeholder}
            onChangeText={onChangeText}
            onFocus={focusHandler}
            {...args}
            />}
          {loading && <ActivityIndicator size="large" color="#000000" style={styles.loader} />}
      </View>
    {error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  loader: {
    marginLeft: 10,
  },
  input: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
    paddingLeft: 10, // Padding for text input
    backgroundColor: 'white',
  },
  nonedit: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
    paddingLeft: 10, // Padding for text input
    backgroundColor: 'white',
    color: 'grey',
  },
  error: {
    color: "red",
    fontSize:12,
    paddingLeft: 5
  }
});

export default FormInput;