import React, { useState, useContext } from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity } from 'react-native';
import { SearchIcon, AddIcon } from '../Icons';
import AuthContext from '../hooks/AuthContext';

const SearchBar = ({ onSearch, navigation }) => {
  const [searchText, setSearchText] = useState('');
  const [focused, setFocused] = useState(false);
  const { role} = useContext(AuthContext);
 const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleSearch = async () => {
    if(searchText) {
      navigation.navigate('SearchStore', {searchText: searchText });
      // await triggerNetworkCall(null, `?term=${encodeURIComponent(searchText)}`);
    }
  };

  return (
    <View style={[styles.container, focused && styles.focused]}>
      <TextInput
        style={[styles.input]}
        placeholder="Search Store..."
        value={searchText}
        onChangeText={setSearchText}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onSubmitEditing={handleSearch}
      />
      <TouchableOpacity style={styles.button} onPress={handleSearch} disabled={searchText === ''}>
        <SearchIcon height={24} width={24}/>
      </TouchableOpacity>
      {(role === 'admin' || role === 'manager') && <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Store', { isEdit:false })}>
        <AddIcon height={24} width={24}/>
      </TouchableOpacity>}

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  input: {
    flex: 1,
    height: 40,
    marginRight: 8,
    outlineWidth:0
  },
  focused: {
    borderColor: '#122',
  },
  button: {
    backgroundColor: 'transparent',
    padding: 8,
    borderRadius: 4,
  },
});

export default SearchBar;
