import { useState, useContext } from 'react';
import AuthContext from './AuthContext';

const useAuthenticatedFetch = (url, method) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { accessToken, signOut, setGlobalError } = useContext(AuthContext);

  const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';
  const token = accessToken;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const triggerNetworkCall = async (body=null, url_params='') => {
    setIsLoading(true);

    try {
      const options = {
        method,
        headers,
        ...(body && {body: JSON.stringify(body)}),
      };

      const response = await fetch(`${baseUrl}${url}${url_params}`, options);
      const responseData = await response.json();

      if (response.ok) {
        setError(null)
        setData(responseData);
      } else {
        if(response?.status === 401){
          setGlobalError({message: 'Invalid Access token! Try login with valid credentials'})
          signOut()
        }
        setError(responseData); // Adjust error handling based on your API response structure
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, data, triggerNetworkCall };
};

export default useAuthenticatedFetch;

