import React from 'react';
import { View, Modal, StyleSheet,Text, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';

const GenericModal = ({ isVisible, onClose, onCreateUser, onChangePassword, role }) => {
  return (
    <Modal
      animationType="none"
      visible={isVisible}
      onRequestClose={onClose}
      transparent
    >
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          {role === 'admin' && <TouchableOpacity style={styles.button} onPress={onCreateUser}>
            <Text style={styles.buttonText}>Create New User</Text>
          </TouchableOpacity>}
          <TouchableOpacity style={styles.button} onPress={onChangePassword}>
            <Text style={styles.buttonText}>Change Password</Text>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    width: 300,
    gap: 10
  },
   button: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: '1px solid gray'
  },
  buttonText: {
    textTransform: 'uppercase',
    color: 'black',
  }
});

export default GenericModal;
