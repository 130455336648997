import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FormPicker from './FormPicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DeleteIcon } from '../Icons';

const StoreScheduler = ({ fulfillmentTypes, updateFulfillmentScheduler, isSFS }) => {
    const today = new Date();
    const [fulfillmentData, setFulfillmentData] = useState([])


    useEffect(() => {
        const updatedFulfillment = fulfillmentTypes.map((data) => {
            if (data.type == "SFS") {

                return {
                    ...data,
                    featureEnabled: isSFS,
                }
            }
            else return {
                ...data
            }
        })
        setFulfillmentData(updatedFulfillment)
    }, [fulfillmentTypes])

    const updateStatus = (index, value) => {
        if (value === "ENABLED") {
            updateFulfillmentScheduler(index, 'enabled', true)
        }
        else if (value === "DISABLED") {
            updateFulfillmentScheduler(index, 'enabled', false)
        } else {
            updateFulfillmentScheduler(index, 'enabled', '')
        }
    }

    const updateScheduleType = (index, value) => {
        if (value === "IMMEDIATE" || value === "") {
            updateFulfillmentScheduler(index, 'immediate', true)
            setFulfillmentData((fulfillment) =>
                fulfillment.map((item, idx) => {
                    if (idx === index) {
                        return {
                            ...item,
                            turnOnTime: "",
                            turnOffTime: ""
                        }
                    }
                    return item;
                })
            );
            updateFulfillmentScheduler(index, 'turnOnTime', "")
            updateFulfillmentScheduler(index, 'turnOffTime', "")
        } else {
            updateFulfillmentScheduler(index, 'immediate', false)
        }

    }

    const updateTurnOnDateTime = (index, value) => {
        if (value) {
            const formattedDate = formatDate(value);
            updateFulfillmentScheduler(index, 'turnOnTime', formattedDate)
        }
        else {
            updateFulfillmentScheduler(index, 'turnOnTime', '')
        }
    }

    const updateTurnOffDateTime = (index, value) => {
        if (value) {
            const formattedDate = formatDate(value);
            updateFulfillmentScheduler(index, 'turnOffTime', formattedDate)
        }
        else {
            updateFulfillmentScheduler(index, 'turnOffTime', '')
        }
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const parseDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day);
    }

    const handleClear = (index) => {
        updateFulfillmentScheduler(index, 'turnOnTime', "")
        updateFulfillmentScheduler(index, 'turnOffTime', "")
        updateFulfillmentScheduler(index, 'immediate', "")
        updateFulfillmentScheduler(index, 'enabled', "")

    }

    return (
        <View style={styles.container}>
            <View style={styles.headerRow}>
                <Text style={styles.headerText}>Feature</Text>
                <Text style={styles.headerText}>Current Status</Text>
                <Text style={styles.headerText}>Change Status</Text>
                <Text style={styles.headerText}>Schedule-Type</Text>
                <Text style={styles.headerText}>Turn On Time</Text>
                <Text style={styles.headerText}>Turn Off Time</Text>
            </View>
            {
                fulfillmentData.map((fulfillmentType, index) => (
                    <View style={styles.row} key={fulfillmentType.type}>
                        <View style={styles.pickerContainer}>
                            <Text style={styles.cellText}>{fulfillmentType.type}</Text>
                        </View>
                        <View style={styles.pickerContainer}>
                            <Text style={styles.cellText}>{fulfillmentType.featureEnabled ? "ENABLED" : "DISABLED"}</Text>
                        </View>
                        <View style={styles.pickerContainer}>
                            <FormPicker
                                value={fulfillmentType.enabled === "" ?
                                    fulfillmentType.enabled : fulfillmentType.enabled === false ?
                                        "DISABLED" : "ENABLED"}
                                options={
                                    fulfillmentType.featureEnabled === false
                                        ? [
                                            { label: 'Select-Status', value: '' },
                                            { label: 'ENABLED', value: 'ENABLED' }
                                        ]
                                        : [
                                            { label: 'Select-Status', value: '' },
                                            { label: 'DISABLED', value: 'DISABLED' }
                                        ]
                                }
                                isEnabled={index <= 0}
                                onChange={(value) => updateStatus(index, value)}
                            />
                        </View>
                        <View style={styles.pickerContainer}>
                            <FormPicker
                                value={(fulfillmentType.turnOffTime !== "" || fulfillmentType.turnOnTime !== "") ? "FUTURE" :
                                    fulfillmentType?.immediate === "" ? "" : undefined
                                }
                                isEnabled={index <= 0 &&
                                    (fulfillmentType.featureEnabled !== fulfillmentType.enabled)
                                    && fulfillmentType.enabled !== ''}
                                options={[
                                    { label: 'Select-Schedule-Type', value: '' },
                                    { label: 'IMMEDIATE', value: "IMMEDIATE" },
                                    { label: 'FUTURE', value: "FUTURE" },
                                ]}
                                onChange={(value) => updateScheduleType(index, value)}
                            />
                        </View>
                        <View style={styles.datePickerContainer}>
                            <DatePicker
                                placeholderText='Turn On Date'
                                portalId="root-portal-calender"
                                isClearable
                                selected={fulfillmentType.turnOnTime === "" ? "" : parseDate(fulfillmentType.turnOnTime)}
                                minDate={today}
                                // showTimeSelect
                                dateFormat="yyyy-MM-dd"
                                disabled={fulfillmentType?.immediate === true || index > 0
                                    || !fulfillmentType.enabled || fulfillmentType?.immediate === ""
                                }
                                onChange={(date) => updateTurnOnDateTime(index, date)}
                            />
                        </View>
                        <View style={styles.datePickerContainer}>
                            <DatePicker
                                placeholderText='Turn Off Date'
                                portalId="root-portal-calender"
                                isClearable
                                selected={fulfillmentType.turnOffTime === "" ? "" : parseDate(fulfillmentType.turnOffTime)}
                                minDate={today}
                                // showTimeSelect
                                dateFormat="yyyy-MM-dd"
                                disabled={fulfillmentType?.immediate === true || index > 0
                                    || fulfillmentType.enabled || fulfillmentType?.immediate === ""
                                }
                                onChange={(date) => updateTurnOffDateTime(index, date)}
                            />
                            <TouchableOpacity style={styles.iconContainer} onPress={() => handleClear(index)}>
                                <DeleteIcon height={24} width={24} />
                            </TouchableOpacity>
                        </View>
                    </View>
                ))

            }

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    headerText: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 10,
    },
    cellText: {
        // flex: 1,
        width: 100,
        backgroundColor: "white",
        textAlign: 'center',
        borderWidth: 1,
        borderColor: "grey",
        padding: 10,
        borderRadius: 10,

    },
    pickerContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
    },
    datePickerContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        flexDirection: "row"
    },
    iconContainer: {
        marginLeft: 10
    }
});

export default StoreScheduler;
