import React, { useState, useMemo, useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View, TextInput, CheckBox } from 'react-native';
import DataTable from '../components/DataTable';


const sampleData = [
    {
        sku_id: '12345',
        product_name: 'Product A',
        upc: '1234',
        style: 'Style 1',
        color: 'Red',
        size: 'M',
        current_status: 'Available',
        // exclude_and_include: false,
        // exclude_date: '',
        // include_date: '2024-07-20',
    },
    {
        sku_id: '67890',
        product_name: 'Product B',
        upc: '9876',
        style: 'Style 2',
        color: 'Blue',
        size: 'L',
        current_status: 'Out of Stock',
        // exclude_and_include: true,
        // exclude_date: '2024-07-21',
        // include_date: '',
    },
];

const sampleData2 = [
    {
        sku_id: '12345',
        product_name: 'Product A',
        upc: '1234',
        // style: 'Style 1',
        // color: 'Red',
        // size: 'M',
        current_status: 'Available',
        exclude_and_include: false,
        exclude_date: '',
        include_date: '2024-07-20',
    },
    {
        sku_id: '67890',
        product_name: 'Product B',
        upc: '9876',
        // style: 'Style 2',
        // color: 'Blue',
        // size: 'L',
        current_status: 'Out of Stock',
        exclude_and_include: true,
        exclude_date: '2024-07-21',
        include_date: '',
    },
];

const SearchableDataTable = () => {
    return (
        <View style={{ flex: 1 }}>
            {/* <EditableTable
                tableName="User Information"
                data={sampleData}
                isSortable={true}
            /> */}
            <DataTable
        tableName="User Information"
        data={sampleData2}
        isSortable={true}
    />
        </View>
    )
}

export default SearchableDataTable;