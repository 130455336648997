import { Pressable, StyleSheet, Text } from 'react-native';
import {AccountIcon, ArrowLeftIcon, ArrowRightIcon, CloseIcon, LogoutIcon} from '../Icons'

const iconMap = {
  "logout": <LogoutIcon height={24} width={24}/>,
  "arrow-back": <ArrowLeftIcon height={24} width={24}/>,
  "arrow-forward": <ArrowRightIcon height={24} width={24}/>,
  "account": <AccountIcon height={24} width={24}/>,
  "close": <CloseIcon height={20} width={20}/>,
}

export default function IconButton({ icon, label, includeText=true, onPress, disabled }) {

  const buttonStyle = disabled ? styles.disabledButton : styles.iconButton;
  const labelStyle = disabled
    ? styles.disabledIconLabel
    : styles.iconButtonLabel;

  return (
    <Pressable style={buttonStyle} onPress={onPress} disabled={disabled}>
      {iconMap[icon]}
      {includeText && <Text style={labelStyle}>{label}</Text>}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  iconButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 5,
  },
  disabledButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 5,
    opacity: 0.2,
  },
  iconButtonLabel: {
    color: '#000',
    marginTop: 5,
    paddingEnd: 10,
    paddingBottom: 10,
  },
  disabledIconLabel: {
    color: 'lightgray',
    marginTop: 5,
    paddingEnd: 10,
    paddingBottom: 10,
  },
});
