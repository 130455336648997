import React, { useState } from 'react';
import { View, Modal, TouchableOpacity, Text, TouchableWithoutFeedback, ScrollView, StyleSheet } from 'react-native';

const TimePicker = ({heading,selected, placeholder, handleChange, disabled}) => {
  const [modalVisible, setModalVisible] = useState(false);
  
  // const values = ['Closed','12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'];

  const values = ['Closed'];

  for (let i = 0; i < 24; i++) { // Loop through each hour from 0 to 23
    for (let j = 0; j < 60; j += 15) { // Loop through each minute in steps of 15
      const hour = i.toString().padStart(2, '0');
      const minute = j.toString().padStart(2, '0');
      
      const period = i < 12 || i === 24 ? 'AM' : 'PM';
      
      let displayHour = i % 12;
      if (displayHour === 0) displayHour = 12;
      
      values.push(`${displayHour.toString().padStart(2, '0')}:${minute} ${period}`);
    }
  }


  const handleValueClick = (value) => {
    setModalVisible(false);
    handleChange(value);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.textInput}
        disabled={disabled}
        onPress={() => setModalVisible(true)}
      >
        <Text>{selected || placeholder}</Text>
      </TouchableOpacity>
      <Modal
        animationType="none"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modal}>
              <ScrollView style={styles.modalContent}>
              <View style={styles.viewStyle}>
                <Text style={styles.modalHeading}>{heading}</Text>
                  {values.map((value, index) => (
                    <TouchableOpacity
                      key={index}
                      style={styles.modalValue}
                      onPress={() => handleValueClick(value)}
                    >
                      <Text>{value}</Text>
                    </TouchableOpacity>
                  ))}
              </View>
              </ScrollView>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    width: '100%',
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: "white",
    color: 'gray',
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    marginVertical: 10,
    backgroundColor: 'white',
    width: "30%",
    padding: 20,
    borderRadius: 5,
  },
  viewStyle: {
    alignItems: "center"
  },
  modalHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalValue: {
    paddingVertical: 8,
  },
});

export default TimePicker;
