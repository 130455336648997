import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
const RadioButton = ({ label, value, selected, onSelect }) => {
  return (
    <TouchableOpacity style={styles.radioButton} onPress={() => onSelect(value)}>
      <View style={styles.radioCircle}>
        {selected && <View style={styles.selectedRb} />}
      </View>
      <Text style={styles.radioText}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  selectedRb: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#000000',
  },
  radioText: {
    fontSize: 16,
    color: '#000',
  },
});

export default RadioButton;
