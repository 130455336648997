import React, { useState, useRef } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay";
import DashboardTable from "../components/DashboardTable";
import { generateStoreHubApiToken } from "../utils/tokenGenerator";
import { STORE_HUB_API_URL } from "@env";
import AlertModal from "../components/AlertModal";

export default function FileUpload() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const fileInputRef = useRef(null);
  const baseUrl = STORE_HUB_API_URL || "";

  const expectedHeaders = ["sku_id", "sfs_eligible", "sfs_eligible_on_date", "sfs_eligible_off_date"];

  const resetFileInput = () => {
    if (fileInputRef.current) { fileInputRef.current.value = ''; }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setModalMessage("File size exceeds 2MB");
        setModalVisible(true);
        setFile(null);
        setFileUploadResponse(null);
        resetFileInput()
        return;
      }
      setFile(null);
      setFileUploadResponse(null);
      resetFileInput()
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result;
        const [headerLine] = fileContent.split("\n");
        const headers = headerLine
          .split(",")
          .map((header) => header.trim().replace(/"/g, ""));
        const hasValidHeaders = expectedHeaders.every((header) =>
          headers.includes(header)
        );

        if (hasValidHeaders) {
          setFile(selectedFile);
          setFileUploadResponse(null);
        } else {
          setModalMessage("Invalid CSV headers");
          setModalVisible(true);
          setFile(null);
          setFileUploadResponse(null);
          resetFileInput()
        }
      };

      reader.onerror = () => {
        setModalMessage("Error reading file");
        setModalVisible(true);
      };

      reader.readAsText(selectedFile);
    } else {
      // Handle file picker cancel
      console.log("File selection canceled");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async () => {
    if (!file) {
      setModalMessage("No file selected");
      setModalVisible(true);
      return;
    }

    setLoading(true);
    setModalMessage("");

    const formData = new FormData();

    formData.append("file", file);
    const apiToken = generateStoreHubApiToken();

    try {
      const response = await axios.post(
        `${baseUrl}/products/sfs-eligibility-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      const responseData = response.data;

      if (responseData.runTimeError) {
        setLoading(false);
        throw new Error(responseData.runTimeError);
      }
      if (responseData?.failedCount === 0) {
        setFileUploadResponse(null);
        setFile(null);
        setModalMessage("File loaded successfully");
        setModalVisible(true);
        setLoading(false);
      } else {
        setModalMessage("File loaded successfully");
        setModalVisible(true);
        setFileUploadResponse(responseData);
        setLoading(false);
      }

    } catch (err) {
      if (err.message.includes("400")) {
        setModalMessage("Please choose a valid file and try again");
      } else {
        setModalMessage("Error uploading file");
      }
      setLoading(false);
      setModalVisible(true);
    }
  };

  const transformFailedSummary = (failedSummary) => {
    return failedSummary?.map((item) => {
      return {
        ...item,
        skuList: item.skuList.map((sku) => ({
          skuListItem: sku,
          reason: item.reason,
        })),
      };
    });
  };

  const transformedSummary = transformFailedSummary(
    fileUploadResponse?.failedSummary
  );

  const handleRemove = () => {
    setFile(null);
    resetFileInput();
    if (fileUploadResponse !== null) {
      setFileUploadResponse(null);
    }
  };

  const isDisabled = !file || fileUploadResponse?.runTimeError === null;

  return (
    <>
      {loading && <LoadingOverlay />}
      <View style={styles.container}>
        <Text style={styles.title}>Upload CSV File</Text>
        <TouchableOpacity style={styles.uploadBox} onPress={handleUploadClick}>
          <Text style={styles.uploadText}>Pick CSV File</Text>
        </TouchableOpacity>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".csv"
          onChange={handleFileChange}
        />
        <TouchableOpacity
          style={[styles.uploadBtn, isDisabled && styles.uploadBtnDisabled]}
          onPress={handleUpload}
          disabled={isDisabled}
        >
          <Text style={styles.uploadBtnText}>Upload</Text>
        </TouchableOpacity>
        {file && transformedSummary === undefined && (
          <View style={styles.fileContainer}>
            <Text style={styles.fileName}>{file.name}</Text>
            {fileUploadResponse?.runTimeError !== null && (
              <TouchableOpacity style={styles.removeBtn} onPress={handleRemove}>
                <Text style={styles.removeText}>x</Text>
              </TouchableOpacity>
            )}
          </View>
        )}
        {transformedSummary?.length > 0 && (
          <Text style={styles.fileTitleName}>{file?.name}</Text>
        )}
        {transformedSummary?.length > 0 &&
          transformedSummary?.map((data, i) => {
            return (
              <View style={styles.tableContainer} key={i}>
                <DashboardTable
                  tableName={data?.reason}
                  data={data?.skuList}
                  isSortable={true}
                />
              </View>
            );
          })}
        <AlertModal
          visible={modalVisible}
          message={modalMessage}
          onClose={() => setModalVisible(false)}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
    backgroundColor: "#ffffff",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  title: {
    fontSize: 18,
    marginVertical: 10,
    fontWeight: "500",
  },
  uploadBox: {
    borderWidth: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    height: 200,
    borderRadius: 10,
    borderStyle: "dashed",
    backgroundColor: "#f2f0f0",
  },
  uploadBtn: {
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 15,
    width: "15%",
    height: 55,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
    borderRadius: 10,
    color: "white",
    backgroundColor: "black",
  },
  uploadText: {
    fontSize: 16,
    color: "#000",
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 10,
    borderRadius: 5,
  },
  uploadBtnText: {
    fontSize: 16,
    color: "#ffffff",
  },
  fileName: {
    marginVertical: 10,
    textDecorationLine: "underline",
  },
  fileTitleName: {
    marginVertical: 20,
    textDecorationLine: "underline",
    fontSize: 20,
    textAlign: "left"
  },
  error: {
    color: "red",
    marginTop: 10,
  },
  fileUploadSuccess: {
    fontSize: 14,
    color: "green",
    marginTop: 15,
  },
  tableContainer: {
    width: "100%",
  },
  fileContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15
  },
  removeBtn: {
    padding: 15,
    width: 20,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    backgroundColor: "red",
    marginLeft: 10,
  },
  removeText: {
    fontSize: 16,
    padding: 10,
    color: "white",
    marginBottom: 4,
  },
  uploadBtnDisabled: {
    backgroundColor: "gray",
  },
});
