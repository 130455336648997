import React, { forwardRef } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { Picker } from '@react-native-picker/picker'; // Make sure to install this package

const FormPicker = forwardRef(({ value, error = null, placeholder, onChange, options, loading = false, isEnabled = true, focusHandler, ...args }, ref) => {
  return (
    <View ref={ref}>
      <View style={styles.container}>
        <Picker
          selectedValue={value}
          style={error ? { ...styles.input, borderColor: "red" } : { ...styles.input }}
          onValueChange={onChange}
          onFocus={focusHandler}
          {...args}
          enabled={isEnabled}
        >
          {placeholder && <Picker.Item label={placeholder} value={null} />}
          {options.map((option, index) => (
            <Picker.Item key={index} label={option.label} value={option.value} />
          ))}
        </Picker>
        {loading && <ActivityIndicator size="large" color="#000000" style={styles.loader} />}
      </View>
      {error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  loader: {
    marginLeft: 10,
  },
  input: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
    paddingLeft: 10,
    backgroundColor: 'white',
  },
  error: {
    color: "red",
    fontSize: 12,
    paddingLeft: 5
  }
});

export default FormPicker;
