import React, { useRef, useEffect } from 'react';
import { View } from 'react-native';


const ClickOutside = ({
    children,
    exceptionRef,
    onClick,
}) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickListener = (event) => {
            let clickedInside = false;
            if (exceptionRef) {
                clickedInside =
                    (wrapperRef.current &&
                        wrapperRef.current.contains(event.target)) ||
                    (exceptionRef.current && exceptionRef.current === event.target) ||
                    (exceptionRef.current &&
                        exceptionRef.current.contains(event.target));
            } else {
                clickedInside =
                    wrapperRef.current &&
                    wrapperRef.current.contains(event.target);
            }

            if (!clickedInside) onClick();
        };

        document.addEventListener('mousedown', handleClickListener);

        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, [exceptionRef, onClick]);

    return (
        <View ref={wrapperRef}>
            {children}
        </View>
    );
};

export default ClickOutside;
