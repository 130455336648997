import React, {useEffect} from "react";
import { View, Text,TouchableOpacity, StyleSheet, } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import FormInput from "./FormInput"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from "./TimePicker";
import FormPicker from "./FormPicker";
import { DeleteIcon } from "../Icons";
import {formatDate} from "../utils/formatDate";

const SpecialHours = ({hours, error, setHours, updateSpecialHour, timezone, deleteSpecialHour}) => {

  const addHour = () => {
    setHours('special_hours','', { date: "", end_date:"", open_at: "", close_at: "", reason: "", comments: "" })
  }

  return (
    <View>
      <View style={styles.spaceWrapper}/>
      {hours.map((hour, index) => (
        <View key={index} style={styles.row}>
          <DatePicker withPortal
       portalId="root-portal-calender" showYearDropdown placeholderText="Start Date" selected={hour.date ? new Date(hour.date) : null} onChange={(datetime) => updateSpecialHour(index, 'date', formatDate(datetime))}  minDate={new Date()}  />
        <DatePicker withPortal
        portalId="root-portal-calender" showYearDropdown placeholderText="End Date" selected={hour.end_date ? new Date(hour.end_date) : null} onChange={(datetime) => updateSpecialHour(index, 'end_date', formatDate(datetime))}  minDate={new Date()} />
          <TimePicker heading="Open At" placeholder="Open At" selected={hour.open_at} handleChange={(datetime) =>updateSpecialHour(index, 'open_at', datetime)}/>
          <TimePicker heading="Close At" placeholder="Close At" disabled={hour.open_at === 'Closed'} selected={hour.close_at} handleChange={(datetime) =>updateSpecialHour(index, 'close_at', datetime)}/>
          <FormPicker
              value={hour.reason}
              placeholder="Reason"
              options={[
                { label: 'HOLIDAY', value: 'HOLIDAY' },
                { label: 'EMERGENCY', value: 'EMERGENCY' },
              ]}
              onChange={(value) => updateSpecialHour(index, 'reason', value)}
            />
          <View style={styles.input}>
            <FormInput placeholder="Comments" value={hour.comments} onChangeText={(text) => updateSpecialHour(index, 'comments', text)} />
          </View>
          <TouchableOpacity onPress={() => deleteSpecialHour(index)}>
            <DeleteIcon height={24} width={24}/>
          </TouchableOpacity>
        </View>
      ))}
      {error && <Text style={styles.error}>Please priovide Start, End dates, Open At, Close At and Reason</Text>}
      <TouchableOpacity style={styles.button} onPress={addHour}>
             <AntDesign name="plus" size={24} color="black" />
             <Text>Add more</Text>
          </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    gap: "10px",
    alignItems: 'center',
    marginBottom: 10,
  },
  spaceWrapper: {
    marginBottom: 10,
  },
  input: {
    flex: 2
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid gray',
    width: '120px',
    gap: '8px',
    backgroundColor: 'transparent',
    padding: 8,
    borderRadius: 4,
  },
  error: {
    color: "red",
    fontSize:12,
    paddingLeft: 5,
    marginBottom: 5
  }
});

export default SpecialHours;