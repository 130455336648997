import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  CheckBox,
  FlatList,
  Image,
} from "react-native";
import FormPicker from "./FormPicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./skuDatePicker.css";
import { generateStoreHubApiToken } from "../utils/tokenGenerator";
import { STORE_HUB_API_URL } from "@env";
import axios from "axios";
import ClickOutside from "./ClickOutisde";
import DateModal from "../components/DateModal";
import AlertModal from "./AlertModal";

export const SkuEditTable = ({
  tableData = [],
  setTableData,
  setIsLoading,
  productFilter = false,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const itemsPerPage = 30;
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [selectedColors, setSelctedColors] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState({ includeDate: null, excludeDate: null });
  const [modalTitle, setModalTitle] = useState("");
  const [isAlertModalVisible, setAlertModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const today = new Date();

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, filteredData.length);
  const numberOfPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    const updatedInitialData = tableData.map((data) => {
      return {
        ...data,
        changed_status: "",
        isEdited: false,
      };
    });
    setData(updatedInitialData);
    setFilteredData(updatedInitialData);
    setSelctedColors([]);
    setSelectedProducts([]);
    setPage(0);
  }, [tableData]);

  useEffect(() => {
    let filterArray = productFilter ? selectedProducts : selectedColors;
    let filteredData =
      filterArray.length > 0
        ? productFilter
          ? data.filter((item) => filterArray.includes(item.productName))
          : data.filter((item) => filterArray.includes(item.color))
        : data;
    setIsChecked(false);
    setPage(0)
    const updatedData = filteredData.map((data) => {
      return {
        ...data,
        changed_status: "",
        isEdited: false,
      };
    });

    // const uniqueOnDates = [...new Set(filteredData.map(item => item.sfsEligibleOnDate))];
    // const uniqueOffDates = [...new Set(filteredData.map(item => item.sfsEligibleOffDate))];

    // if (uniqueOnDates.length === 1 && uniqueOnDates[0] !== null) {
    //   setSelectedDate(prevState => ({
    //     ...prevState,
    //     includeDate: uniqueOnDates[0]
    //   }));
    // }

    // if (uniqueOffDates.length === 1 && uniqueOffDates[0] !== null) {
    //   setSelectedDate(prevState => ({
    //     ...prevState,
    //     excludeDate: uniqueOffDates[0]
    //   }));
    // }

    setFilteredData(updatedData);
  }, [selectedColors.length, selectedProducts.length]);


  useEffect(() => {
    const updatedData = filteredData?.map(item => {
      if (item.changed_status === "") {
        return {
          ...item,
          sfsEligibleOnDate: formatDate(selectedDate.includeDate) || item.includeDate,
          sfsEligibleOffDate: formatDate(selectedDate.excludeDate) || item.excludeDate
        };
      } else {
        return {
          ...item,
          sfsEligibleOnDate: null,
          sfsEligibleOffDate: null,
        };
      }
    });
  
    setFilteredData(updatedData);
  }, [isModalVisible]);
  
  const handleIncludeDate = () => {
    setModalTitle("Include Date");
    setIsModalVisible(true);
  };

  const handleExcludeDate = () => {
    setModalTitle("Exclude Date");
    setIsModalVisible(true);
  };

  const openModal = (title) => {
    setModalTitle(title);

    const dateToEdit = title === 'Include Date' ? selectedDate.includeDate : selectedDate.excludeDate;

    setSelectedDate(prevDates => ({
      ...prevDates,
      [title === 'Include Date' ? 'includeDate' : 'excludeDate']: dateToEdit
    }));

    setIsModalVisible(true);
  };


  const handleApply = () => {
    const isIncludeDate = modalTitle === 'Include Date';

    setFilteredData(prevData =>
      prevData.map(item => ({
        ...item,
        isEdited: true,
        sfsEligibleOnDate: isIncludeDate ? formatDate(selectedDate.includeDate) : item.sfsEligibleOnDate,
        sfsEligibleOffDate: !isIncludeDate ? formatDate(selectedDate.excludeDate) : item.sfsEligibleOffDate,
      }))
    );

    setSelectedDate(prevDates => ({
      ...prevDates,
      [isIncludeDate ? 'includeDate' : 'excludeDate']: selectedDate[isIncludeDate ? 'includeDate' : 'excludeDate']
    }));

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderTableCell = (text, STYLE = {}) => {
    return (
      <View
        style={[
          styles.tableheader,
          STYLE,
        ]}
      >
        <Text style={styles.header}>{text}</Text>
      </View>
    );
  };

  const renderHeaderCellIncludeExclude = useCallback((text, STYLE = {}) => {
    const label = text === "Include Date";
    const isFilterData = filteredData?.length === 0;
    return (
      <View
        style={[
          styles.tableheader,
          { position: "relative", alignItems: "center" },
        ]}
      >
        <View style={styles.flexContainer}>
          <Text style={[styles.header, { fontWeight: "bold" }]}>{text}</Text>
  
          <TouchableOpacity
            disabled={isFilterData}
            onPress={() => openModal(text)}
            style={styles.iconStyle}
          >
            <Image
              source={require("../../assets/calendar.png")}
              style={styles.image}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }, [filteredData]);
  
  const renderExcelData = (value) => {
    return (
      <View style={styles.tableheader}>
        <Text style={[styles.header, { fontWeight: "normal" }]}>{value}</Text>
      </View>
    );
  };

  const renderChangeStatus = (
    text,
    STYLE = { position: "relative", alignItems: "center" }
  ) => {
    return (
      <View style={[styles.tableheader, STYLE]}>
        <View style={styles.flexContainer}>
          <Text style={styles.header}>{text}</Text>
          <input
            type="checkbox"
            checked={isChecked}
            style={styles.checkbox}
            onChange={(event) => {
              handleChangeStatusCheckBox(event);
            }}
          />
        </View>
      </View>
    );
  };

  const handleChangeStatusCheckBox = (event) => {
    const newData = filteredData.map((d) => {
      return {
        ...d,
        changed_status: !isChecked,
        isEdited: true,
        sfsEligibleOffDate: null,
        sfsEligibleOnDate: null,
      };
    });
    console.log("called");
    setFilteredData(newData);
    setIsChecked((checked) => !checked);
  };

  const changeFilter = () => {
    setIsFilterVisible((isFilterVisible) => !isFilterVisible);
  };
  const renderWithFilter = (value, type) => {
    return (
      <View
        style={[
          styles.tableheader,
          { position: "relative", alignItems: "center" },
        ]}
      >
        <View style={styles.flexContainer}>
          <Text style={[styles.header, { fontWeight: "bold" }]}>{value}</Text>
          {/* <Text onPress={() => changeFilter()}>...</Text> */}

          <TouchableOpacity onPress={changeFilter} style={styles.iconStyle}>
            {/* <Text style={styles.btnStyle}>Download</Text> */}
            <Image
              source={require("../../assets/filterIcon.png")}
              style={styles.image}
            />
          </TouchableOpacity>
        </View>

        {tableData.length > 0 &&
          isFilterVisible &&
          type === "color" &&
          renderColorFilter()}
        {tableData.length > 0 &&
          isFilterVisible &&
          type === "product" &&
          productFilter &&
          renderProductNameFilter()}
      </View>
    );
  };

  const renderCurrentStatus = (value) => {
    return (
      <View style={styles.tableheader}>
        <Text style={[styles.header, { fontWeight: "normal" }]}>
          {value ? "INCLUDED" : "EXCLUDED"}
        </Text>
      </View>
    );
  };

  const renderDropDown = (value, key, index, skuId) => {
    return (
      <View style={styles.tableheader}>
        <FormPicker
          value={value}
          options={[
            { label: "Select-Status", value: "" },
            { label: "INCLUDED", value: true },
            { label: "EXCLUDED", value: false },
          ]}
          isEnabled
          onChange={(value) => handleDropdownChange(index, key, value, skuId)}
        />
      </View>
    );
  };

  const renderDatePicker = (value, key, placeholder, index, tableInfo) => {
    return (
      <View style={styles.tableheader}>
        <DatePicker
          disabled={tableInfo.changed_status !== ""}
          showYearDropdown
          isClearable
          minDate={today}
          className="skuDatePicker"
          placeholderText={placeholder}
          popperPlacement="top-end"
          selected={value ? parseDate(value) : null}
          dateFormat="yyyy-MM-dd"
          onChange={(event, selectedDate) =>
            handleDateChange(index, key, event, tableInfo.skuId)
          }
        />
      </View>
    );
  };

  const renderItem = (item, selectionList) => (
    <View style={styles.colorItem}>
      <Text>{item}</Text>
      <input
        type="checkbox"
        checked={selectionList.includes(item)}
        style={styles.checkbox}
        value={item}
        onChange={(event) => {
          handleFilterBoxChange(event);
        }}
      />
    </View>
  );

  const handleFilterBoxChange = useCallback((event) => {
    const checked = event.target.checked;
    const filter = event.target.value;

    let temp = productFilter ? selectedProducts : selectedColors;

    if (checked && filter) {
      productFilter
        ? setSelectedProducts([...temp, filter])
        : setSelctedColors([...temp, filter]);
    } else {
      const updatedFilterValues = temp.filter((v) => v !== filter);
      productFilter
        ? setSelectedProducts(updatedFilterValues)
        : setSelctedColors(updatedFilterValues);
    }
    if(page !== 0){
      setPage(0);
    }
  },[productFilter, selectedProducts, selectedColors, setSelectedProducts, setSelctedColors, page]);

  const renderColorFilter = () => {
    const availableColors = tableData.reduce((acc, curr) => {
      if (!acc.includes(curr.color)) {
        acc.push(curr.color);
      }
      return acc;
    }, []);

    // console.log(availableColors)

    return (
      <ClickOutside onClick={() => setIsFilterVisible(false)}>
        <View style={styles.dropdownContainer}>
          <FlatList
            data={availableColors?.sort()}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => renderItem(item, selectedColors)}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </ClickOutside>
    )

  }


  const renderProductNameFilter = () => {
    const availableProducts = tableData.reduce((acc, curr) => {
      if (!acc.includes(curr.productName)) {
        acc.push(curr.productName);
      }
      return acc;
    }, []);

    return (
      <ClickOutside onClick={() => setIsFilterVisible(false)}>
        <View style={styles.dropdownContainer}>
          <FlatList
            data={availableProducts?.sort()}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => renderItem(item, selectedProducts)}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </ClickOutside>
    )

  }

  const handleDateChange = (testIndex, field, event, skuId) => {
    setFilteredData(prevData => {
      const newData = [...prevData];
      const index = newData.findIndex(item => item.skuId === skuId);
      if (event) {
        newData[index] = {
          ...newData[index],
          [field]: formatDate(event)
        };
      } else {
        newData[index] = {
          ...newData[index],
          [field]: null
        };
      }
      newData[index]["isEdited"] = true;
      return newData;
    });
  };

  const handleDropdownChange = (testIndex, field, value, skuId) => {
    const newData = [...filteredData];
    const index = newData.findIndex(item => item.skuId === skuId);
    if (index < newData.length) {
      newData[index][field] = value;
      newData[index]["isEdited"] = true;
      if (value !== "") {
        newData[index]["sfsEligibleOnDate"] = null
        newData[index]["sfsEligibleOffDate"] = null
      }
      setData(newData);
    }
  };

  const isPST = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Pacific Standard Time (PST) generally corresponds to 'America/Los_Angeles'
    return timeZone === 'America/Los_Angeles';
  };


  const updateSkus = async () => {
    const currentDateTime = new Date();
    const hours = String(currentDateTime.getHours())
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const today = formatDate(currentDateTime);
    console.log(hours, day)
    const editedSkus = filteredData.filter((sku) => sku.isEdited === true);
    if (editedSkus.length > 0) {
      setIsLoading(true)
      const baseUrl = STORE_HUB_API_URL || "https://store-fulfillments.dev.alo.technology/bopis";
      const data = editedSkus.map((sku) => {
        const { skuId, changed_status, sfsEligibleOffDate, sfsEligibleOnDate, sfsEligible } = sku
        let temp = null;
        if (changed_status === null || changed_status === "") {
          if (sfsEligibleOffDate || sfsEligibleOnDate) {
            temp = null
          }
          else {
            temp = sfsEligible
          }
        }
        else {
          temp = changed_status === "true" ? true : false
        }
        return {
          skuId,
          sfsEligible: temp,
          sfsEligibleOffDate, sfsEligibleOnDate
        }
      })

      const uniqueDates = editedSkus.reduce((acc, sku) => {
        const { sfsEligibleOffDate, sfsEligibleOnDate } = sku;
        const dates = [sfsEligibleOffDate, sfsEligibleOnDate].filter(date => date !== null && date !== undefined);

        dates.forEach(date => {
          if (!acc.includes(date)) {
            acc.push(date);
          }
        });

        return acc;
      }, []);

      console.log("isPST", isPST())
      if (uniqueDates.length > 0 && uniqueDates.includes(today) && isPST()) {
        if (hours > 6) {
          setModalMessage(`The schedular for today has already been run please select another future date `);
          setAlertModalVisible(true);
          setIsLoading(false)
          return
        }
      }

      const apiToken = generateStoreHubApiToken();
      try {
        const response = await axios.put(`${baseUrl}/products/eligbility-update`,
          data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          }
        })
        setIsLoading(false)
        setTableData([])
        setSelectedProducts([])
        setSelctedColors([])
        setModalMessage(`skus Updated`);
        setAlertModalVisible(true);
      }
      catch (err) {
        setIsLoading(false)
        console.log(err)
      }

    }
    else {
      setModalMessage(`No Sku Has been Edited`);
      setAlertModalVisible(true);
    }
  }

  //   return (
  //     <ClickOutside onClick={() => setIsFilterVisible(false)}>
  //       <View style={styles.dropdownContainer}>
  //         <FlatList
  //           data={availableColors?.sort()}
  //           keyExtractor={(item, index) => index.toString()}
  //           renderItem={({ item }) => renderItem(item, selectedColors)}
  //           showsVerticalScrollIndicator={false}
  //         />
  //       </View>
  //     </ClickOutside>
  //   );
  // };


  const handleModalDate = (date) => {
    setSelectedDate(prevDates => ({
      ...prevDates,
      includeDate: modalTitle === 'Include Date' ? date : prevDates.includeDate,
      excludeDate: modalTitle === 'Exclude Date' ? date : prevDates.excludeDate,
    }));
  }

  const formatDate = (date) => {
    if (!date) return null;
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  //   const parseDate = (dateString) => {
  //     const [year, month, day] = dateString.split("-");
  //     return new Date(year, month - 1, day);
  //   };

  const parseDate = (dateString) => {
    if (dateString instanceof Date) {
      return dateString;
    }

    if (typeof dateString === "string") {
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day);
    }

    return null;
  };

  return (
    <>
      <AlertModal
        visible={isAlertModalVisible}
        message={modalMessage}
        onClose={() => setAlertModalVisible(false)}
      />
      <View style={{ marginHorizontal: 10 }}>
        <View style={styles.tablerow}>
          {renderTableCell("Sku Id")}
          {productFilter
            ? renderWithFilter("Product Name", "product")
            : renderTableCell("Product Name")}
          {productFilter
            ? renderTableCell("Color")
            : renderWithFilter("Color", "color")}
          {renderTableCell("Current Status")}
          {renderChangeStatus("Change Status")}
          {renderHeaderCellIncludeExclude("Include Date")}
          {renderHeaderCellIncludeExclude("Exclude Date")}
        </View>
        {filteredData.slice(from, to).map((item, k) => {
          return (
            <View style={styles.tablerow} key={k}>
              {renderExcelData(item.skuId)}
              {renderExcelData(item.productName)}
              {renderExcelData(item.color)}
              {renderCurrentStatus(item.sfsEligible)}
              {renderDropDown(
                item.changed_status,
                "changed_status",
                k,
                item.skuId
              )}
              {renderDatePicker(
                item.sfsEligibleOnDate,
                "sfsEligibleOnDate",
                "Enter include date",
                k,
                item
              )}
              {renderDatePicker(
                item.sfsEligibleOffDate,
                "sfsEligibleOffDate",
                "Enter exclude date",
                k,
                item
              )}
            </View>
          );
        })}
        {data.length > itemsPerPage && (
          <View style={styles.paginationFooter}>
            <TouchableOpacity
              style={styles.paginationButton}
              disabled={page === 0}
              onPress={() => setPage(page - 1)}
            >
              <Text>{`< Previous`}</Text>
            </TouchableOpacity>
            <Text>
              {page + 1} of {numberOfPages}
            </Text>
            <TouchableOpacity
              style={styles.paginationButton}
              disabled={page === numberOfPages - 1}
              onPress={() => setPage(page + 1)}
            >
              <Text>{`Next >`}</Text>
            </TouchableOpacity>
          </View>
        )}
        {data.length > 0 && (
          <View style={styles.updateButtonContainer}>
            <TouchableOpacity style={styles.updateButton} onPress={updateSkus}>
              <Text style={styles.updateButtonText}>
                UPDATE SKU INCLUSION/ EXCLUSION
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <DateModal
          isVisible={isModalVisible}
          onClose={handleCancel}
          onApply={handleApply}
          selectedDate={modalTitle === 'Include Date' ? selectedDate.includeDate : selectedDate.excludeDate}
          setSelectedDate={setSelectedDate}
          title={modalTitle}
          placeholder={"date"}
          handleModalDate={handleModalDate}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    textAlign: "center",
    marginHorizontal: "auto",
    paddingVertical: 20,
    fontWeight: "700",
    fontSize: 22,
  },
  section: {
    marginHorizontal: 20,
    paddingVertical: 10,
    fontWeight: "600",
    fontSize: 18,
  },
  tablerow: {
    display: "flex",
    flexDirection: "row",
  },
  headerText: {
    textAlign: "center",
    fontSize: 12,
    paddingVertical: 5,
  },
  header: {
    textAlign: "center",
    fontSize: 12,
    paddingVertical: 5,
    fontWeight: "700",
  },
  tableheader: {
    borderWidth: 1,
    flex: 1,
  },
  bordernonecolumn: {
    flex: 1,
    // borderRightWidth: 0,
  },
  paginationFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
  paginationButton: {
    padding: 10,
  },
  updateButtonContainer: {
    flexDirection: "row",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    marginTop: 20, // Add margin if needed
  },
  updateButton: {
    backgroundColor: "#000", // Black background
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: "auto", // Prevents the button from stretching full width
  },
  updateButtonText: {
    color: "#fff", // White text color
    fontSize: 16,
    textAlign: "center", // Center the text inside the button
    fontWeight: "bold",
  },

  dropdownContainer: {
    height: 150, // Adjust this height as needed for the dropdown appearance
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "10px",
    width: 200,
    zIndex: 99999,
  },
  colorItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    marginLeft: 10,
  },
  image: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
  iconStyle: {
    marginRight: 20,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
