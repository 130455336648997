import React, { useEffect, useState } from 'react'
import { generateStoreHubApiToken } from '../utils/tokenGenerator';
import axios from 'axios';
import RadioButton from './RadioButton';
import EditableTable from './EditableTable';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { STORE_HUB_API_URL } from "@env"
import LoadingOverlay from './LoadingOverlay';
import { SkuEditTable } from './SkuEditTable';
import AlertModal from "../components/AlertModal";
import  NotesSection from "../components/NotesSection";

export const SearchSku = () => {
    const [selectedOption, setSelectedOption] = useState('skuId');
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const baseUrl = STORE_HUB_API_URL || "https://store-fulfillments.dev.alo.technology/bopis";
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
  
    const options = [
        { label: 'Sku Id', value: 'skuId' },
        { label: 'Style Id', value: 'style' },
        { label: 'Product Name', value: 'productName' },
        { label: 'Color', value: 'color' },
    ];

    useEffect(() => {
        setTableData([])
    }, [selectedOption])

    const handleSubmitPress = async () => {
        const apiToken = generateStoreHubApiToken();
        
        if (!searchText.trim()) {
            setModalMessage(`Please enter a value to ${selectedOption}.`);
            setModalVisible(true);
            return; 
        }
    
        if (["skuId", "style"].includes(selectedOption)) {
            if (searchText.length < 5) {
                setModalMessage(`Please enter at least 5 characters for ${selectedOption}.`);
                setModalVisible(true);
                return; 
            }
        }
    
        if (selectedOption === "productName") {
            if (searchText.length < 10) {
                setModalMessage(`Please enter at least 10 characters for ${selectedOption}.`);
                setModalVisible(true);
                return; 
            }
        }

        if(selectedOption === "color"){
            if (searchText.length < 3) {
                setModalMessage(`Please enter at least 3 characters for ${selectedOption}.`);
                setModalVisible(true);
                return; 
            } 
        }
    
        try {
            const { data } = await axios.get(`${baseUrl}/products/query?${selectedOption}=${searchText}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiToken}`,
                }
            });
            setTableData(data.result);
        } catch (error) {
            console.error('API call failed:', error);
            setModalMessage('An error occurred while fetching the data.');
            setModalVisible(true);
        }
    };

    const placeholderText = options.filter((option) => option.value === selectedOption)
    return (
        <>
            <View>
            <NotesSection/>
                <View style={styles.searchButtonWrapper}>
                    <View style={styles.searchContainer}>
                        <TextInput style={styles.search}
                            value={searchText}
                            placeholder={` 🔍 Search by ${placeholderText[0].label}`}
                            onChange={(event) => { setSearchText(event.target.value) }}
                        />
                        <TouchableOpacity
                            style={styles.searchButton}
                            onPress={handleSubmitPress}>
                            <Text style={styles.searchButtonText}>Search</Text></TouchableOpacity>
                    </View>


                    <View style={styles.buttonView}>
                        {options.map((option) => (

                            <RadioButton
                                key={option.value}
                                label={option.label}
                                value={option.value}
                                selected={selectedOption === option.value}
                                onSelect={(value) => setSelectedOption(value)}
                            />
                        ))}
                    </View>
                </View>
                <View style={styles.tableWrapper} >
                    {isLoading && <LoadingOverlay />}
                    {/* {tableData.length > 0 && <EditableTable
                        tableData={tableData}
                        setTableData={setTableData}
                        setIsLoading={setIsLoading} />} */}
                    <SkuEditTable
                        tableData={tableData}
                        setTableData={setTableData}
                        setIsLoading={setIsLoading}
                        productFilter={selectedOption === "color"}
                    />
                    {!tableData.length && <View style={styles.emptyView} ><Text>No Results Found</Text></View>}

                </View>
                <AlertModal
                    visible={modalVisible}
                    message={modalMessage}
                    onClose={() => setModalVisible(false)}
                    />
            </View>
        </>
    )
}


const styles = StyleSheet.create({
    searchButtonWrapper: {
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
        padding: 20,
        backgroundColor: "white",
        borderRadius: 12,
        // Shadow properties for iOS
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        // Shadow properties for Android
        elevation: 5,
    },
    submit: {
        display: 'flex',
        backgroundColor: "black",
        width: 80,
        justifyContent: "center",
        color: "white",
    },
    buttonView: {
        display: 'flex',
        flexDirection: "row",
        gap: 30,
        justifyContent: "center",
        marginTop: 10
    },
    searchContainer: {
        display: 'flex',
        flexDirection: "row",
        gap: 30,
        justifyContent: "center",
    },
    search: {
        borderColor: 'black',
        borderWidth: 1,
        height: 40,
        width: 600,
        paddingLeft: 20,
        borderRadius: 20
    },
    tableWrapper: {
        flex: 1,
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
        padding: 20,
        backgroundColor: "white",
        borderRadius: 12,
        // Shadow properties for iOS
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        // Shadow properties for Android
        elevation: 5,
        marginTop: 10
    },
    emptyView: {
        height: 300,
        color: "white"
    },
    searchButton: {
        backgroundColor: '#000',  // Black background
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        width: 'auto',  // Prevents the button from stretching full width
    },
    searchButtonText: {
        color: '#fff',  // White text color
        fontSize: 14,
        textAlign: 'center',  // Center the text inside the button
        fontWeight: 'bold',
    },
});