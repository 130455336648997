import React from 'react';
import { View, StyleSheet} from 'react-native';
import SearchBar from '../components/SearchBar';
import Stores from './Stores';

const HomeScreen = ({ navigation, route }) => {

  const performSearch = () => {
    //perform search
  }

  return (
    <View style={styles.container}>
      <SearchBar onSearch={performSearch} navigation={navigation}  />
      <View style={styles.horizontalLine} />
      <Stores route={route} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#ffffff'
  },
  flexWrapContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  horizontalLine: {
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    marginTop: 16,
    marginBottom: 16,
  },
});

export default HomeScreen;
