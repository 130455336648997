import React, { useState, useEffect, useContext } from 'react';
import { View, Text, FlatList,StyleSheet,Pressable, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import NoDataMessage from '../components/NoDataMessage';
import LoadingOverlay from '../components/LoadingOverlay';
import useAuthenticatedFetch from '../hooks/useAuthenticatedRequest';
import {DeleteIcon} from '../Icons';
import ConfirmationModal from '../components/ConfirmationModal'
import AuthContext from '../hooks/AuthContext';
import {formatDate} from "../utils/formatDate";

const SearchItem = ({ item, confirmHandler, role }) => {
  const navigation = useNavigation();
  const [isHovered, setIsHovered] = useState(false);
  const store = item.basic;
  const address = item.location;
  const hours = item.regular_hours;
  const specialHours = item.special_hours;
  const rowStyle = isHovered ? styles.rowHovered : styles.row;
  const [modalVisibility, setModalVisibility] = useState(false);

  return (
    <View>
    <Pressable
      onPressIn={() => setIsHovered(true)}
      onPressOut={() => setIsHovered(false)}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPress={() => {
        navigation.navigate('Store', { storeData: item, isEdit:true })
      }}
    >
      <View style={rowStyle}>
        <View style={styles.column}>
                <Text style={styles.header}>{store.store_number} {store.store_name}</Text>
                <Text>{address.address_line1}, {address.city}, {address.state}, {address.zipcode}</Text>
                <Text>{address.country}</Text>
            </View>
            <View style={styles.column}>
                <Text style={styles.header}>Regular Hours</Text>
                {Object.entries(hours).map(([day, dayHours]) => (
                <Text key={day}>{day}: {dayHours.open_at} - {dayHours.close_at}</Text>
                ))}
            </View> 
            <View style={styles.column}>  
                <Text style={styles.header}>Special Hours</Text>
                {specialHours.map((specialHour, index) => (
                <Text key={index}>{formatDate(specialHour.date)} {specialHour.open_at} - {specialHour.close_at} {specialHour.reason}</Text>
                ))}
          </View> 
          {role === 'admin' && <TouchableOpacity onPress={() => {setModalVisibility(true)}}>
            <DeleteIcon height={24} width={24}/>
          </TouchableOpacity>}
      </View>
    </Pressable>
              <ConfirmationModal
                isVisible={modalVisibility}
                message='Are you sure you want to delete?'
                onClose={() => {setModalVisibility(false)}}
                onConfirm={() => {
                  confirmHandler(store?.store_id, store?.geo_hash);
                  setModalVisibility(false)
                }}
            />
    </View>
  );
};


const SearchStores = ({route}) => {
  const {role} = useContext(AuthContext)
  const {searchText} =  route.params;
  const [confirm, setConfirm] = useState(false);
  const [deleted, setDeleted] = useState(true);
  
  const { isLoading: deleteLoading, error:deleteError, data: deleteData, triggerNetworkCall: deleteCall } = useAuthenticatedFetch('/api/store', 'DELETE');
  const {data, isLoading, error, triggerNetworkCall} = useAuthenticatedFetch('/api/search', 'GET');
  
  const confirmHandler = async (storeId, hashId) => {
    await deleteCall(null, `/${storeId}/${hashId}`);
  }

  console.log(data)

  useEffect(() => {
    if(deleteData || deleteError) {
      setConfirm(true)
    }
  }, [deleteError, deleteData]);

  useEffect(() => {
    console.log(searchText)
    async function searchCall() {
          await triggerNetworkCall(null, `?term=${encodeURIComponent(searchText)}`);
    }
    searchText && searchCall();
  }, [searchText, deleted])

  const renderItem = ({ item }) => {
    return <SearchItem item={item} confirmHandler={confirmHandler} role={role} />;
  }

  return (
    (isLoading || !data || deleteLoading) ? <LoadingOverlay /> : (
      data.stores.length > 0 ?
      <View style={styles.container}>
        <FlatList
          data={data.stores}
          renderItem={renderItem}
          keyExtractor={(item) => item.basic.store_number+item.basic.store_id}
        />
        <ConfirmationModal
          isVisible={confirm}
          message={(deleteError) ? 'Something went wrong! Please try again' : ( deleteData && 'Store deleted successfully !' )}
          onClose={() => {
            setConfirm(false);
          }}
          onConfirm={() => {
            setConfirm(false);
            setDeleted(!deleted)
          }}
          notify
          {...(deleteError ? { error: true } : { success: true })}
      />
      </View> : <NoDataMessage message={`No Data available for "${searchText}"`} />
    ) 
  );
};


const styles = StyleSheet.create({
    container : {
      padding: 16,
      backgroundColor: '#ffffff'
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      backgroundColor: '#f8f8f8',
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#ddd',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    rowHovered: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      backgroundColor: '#e8e8e8',
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#ddd',
    },
    searchBar: {
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      paddingLeft: 10,
      marginBottom: 10
    },
    column: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      marginBottom: 5,
    },
  });

export default SearchStores;
