import React, { useState, useEffect } from 'react';
import { StyleSheet, TextInput, View, Text } from 'react-native';

export default function TextField({ placeholder, onChangeText, value, required = false, error, ...args }) {

  return (
    <View style={styles.inputContainer}>
      <TextInput 
        style={[styles.input, error ? styles.errorBorder : {}]} 
        placeholder={placeholder}
        onChangeText={onChangeText}
        value={value}
        placeholderTextColor='grey'
        {...args}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    width: 320,
    height: 70,
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
  input: {
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 10,
    paddingLeft: 10,
    fontSize: 16,
    backgroundColor: 'white',
    fontWeight: 'normal',
  },
  errorBorder: {
    borderColor: 'red',
  },
});
