import * as React from 'react';
import { Text, View } from 'react-native';

function SplashScreen() {
  return (
    <View>
      <Text>Loading...</Text>
    </View>
  );
}

export default SplashScreen;
