import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';

const Notification = ({ type, message, duration=3000, onClose=() => {} }) => {
  const [isVisible, setIsVisible] = useState(true);

  const closeNotification = () => {
    setIsVisible(false);
    onClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      closeNotification();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return isVisible ? (
    <View style={styles.container}>
        <View
        style={[
            styles.notification,
            styles[type],
            Platform.OS === 'web' ? styles.webNotification : styles.nativeNotification,
        ]}
        >
        <Text style={styles.message}>{message}</Text>
        </View>
    </View> ) : null
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: 'center',
  },
  notification: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    borderWidth:2,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    // elevation: 5,
    width: '80%',
  },
  webNotification: {
    position: 'fixed',
    bottom: 50,
  },
  nativeNotification: {
    position: 'absolute',
    bottom: 0,
  },
  success: {
    borderColor: '#4CAF50',
  },
  error: {
    borderColor: '#F44336',
  },
  message: {
    color: 'black',
    fontSize: 16,
  },
});

export default Notification;
