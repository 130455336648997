import React, { useState, useContext } from 'react';
import { View, Modal, StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native';
import AuthContext from '../hooks/AuthContext';
import IconButton from './IconButton';

const UserModal = ({ isVisible, selectedOption, onClose, onCreateUser, onChangePassword }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {userName, role} = useContext(AuthContext)

  const handleCreateUser = async () => {
    await onCreateUser({username, password});
    setUsername('');
    setPassword('');
  };

  const handleChangePassword = async () => {
    await onChangePassword({username: userName, password, role:role});
    setPassword('');
  };

  return (
    <Modal
      animationType="none"
      visible={isVisible}
      transparent={true}
    >
            <View style={styles.modalContainer}>
              <TouchableOpacity style={styles.backdrop} onPress={onClose} />
                {selectedOption === 'newUser' ? (
                <View style={styles.modalSection}>
                  <View style={styles.closeButtonContainer}>
                    <IconButton icon="close" label="Account" onPress={onClose} includeText={false} />
                  </View>
                    <Text style={styles.modalSectionTitle}>Create New User</Text>
                    <TextInput
                    placeholder="Username"
                    style={styles.input}
                    value={username}
                    onChangeText={setUsername}
                    />
                    <TextInput
                    placeholder="Password"
                    secureTextEntry
                    style={styles.input}
                    value={password}
                    onChangeText={setPassword}
                    />
                    <TouchableOpacity style={styles.button} onPress={handleCreateUser}>
                        <Text style={styles.buttonText}>Create User</Text>
                    </TouchableOpacity>
                </View>
                ) : (
                <View style={styles.modalSection}>
                  <View style={styles.closeButtonContainer}>
                    <IconButton icon="close" label="Account" onPress={onClose} includeText={false} />
                  </View>
                    <Text style={styles.modalSectionTitle}>Change Password</Text>
                    <TextInput
                    placeholder="New Password"
                    secureTextEntry
                    style={styles.input}
                    value={password}
                    onChangeText={setPassword}
                    />
                    <TouchableOpacity style={styles.button} onPress={handleChangePassword}>
                        <Text style={styles.buttonText}>Change Password</Text>
                    </TouchableOpacity>
                </View>
                )}
            </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'default'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalSection: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    width: 300,
    textAlign: 'center'
},
modalSectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
},
input: {
    marginBottom: 10,
    padding: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
},
 button: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: 'gray'
  },
  buttonText: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 5,
    right: 10,
  }
});

export default UserModal;
