/* eslint-disable no-undef */
import JWT from 'expo-jwt';

export const generateStoreHubApiToken = () => {
    const currentTime = new Date();

    const CLIENT_ID = process.env.STOREHUB_API_CLIENT_ID;
    const CLIENT_SECRET = process.env.STOREHUB_API_CLIENT_SECRET;

    console.log("Loading the signup apps credentials.....");
    return JWT.encode(
        {
            aud: CLIENT_ID,
            //   sub: "1",
            exp: Math.floor(currentTime.getTime() / 1000 + 300),
        },
        CLIENT_SECRET,
        { algorithm: "HS256" }
    );
}
