import React, { useState, useMemo, useEffect } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FlatList } from 'react-native';
import { Parser } from '@json2csv/plainjs';
import fileDownload from 'js-file-download';


const DashboardTable = ({ tableName = 'Table', width = null, data = [], isSortable = false }) => {
    if (data.length === 0) {
        return <></>;
    }
    useEffect(() => {
        setPage(0)
    }, [data])

    const tableHeaders = Object.keys(data[0]);
    const [page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');

    const itemsPerPage = 6;
    const numberOfPages = Math.ceil(data.length / itemsPerPage);

    const from = page * itemsPerPage;
    const to = Math.min((page + 1) * itemsPerPage, data.length);

    const sortedData = useMemo(() => {
        if (!sortColumn) return data;
        const columnIndex = tableHeaders.indexOf(sortColumn);
        return [...data].sort((a, b) => {
            if (a[tableHeaders[columnIndex]] < b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? -1 : 1;
            if (a[tableHeaders[columnIndex]] > b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
    }, [data, sortColumn, sortDirection]);

    const handleSortColumn = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const renderItem = ({ item, index }) => (
        <View style={[styles.row, index === sortedData.slice(from, to).length - 1 && styles.lastRow]}>
            {Object.values(item).map((cell, cellIndex) => (
                <Text style={styles.cell} key={cellIndex}>{cell}</Text>
            ))}
        </View>
    );

    const downloadCSV = () => {
        const json2csvParser = new Parser;
        const csv = json2csvParser.parse(data);

        fileDownload(csv, `${tableName}.csv`);
    };

    return (
        <View style={[styles.container, width ? { width: width } : {}]}>
            <View style={styles.tableBorder}>
                <View style={styles.headerTopBar}>
                    <Text style={styles.headerTopBarText}>{tableName}</Text>
                    <TouchableOpacity onPress={downloadCSV} style={styles.iconStyle}>
                        {/* <Text style={styles.btnStyle}>Download</Text> */}
                        <Image
                            source={require('../../assets/downloadIcon.png')}
                            style={styles.image}
                        />
                    </TouchableOpacity>
                </View>
                <View style={styles.header}>
                    {tableHeaders.map((column, index) => (
                        <>
                            {isSortable ?

                                (<TouchableOpacity style={styles.heading} key={index} onPress={() => handleSortColumn(column)}>
                                    <Text style={{ fontWeight: "bold" }}>
                                        {column}{sortColumn === column ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : ' ↓'}
                                    </Text>
                                </TouchableOpacity>)
                                : (<View style={styles.heading} >
                                    <Text style={{ fontWeight: "bold" }}>
                                        {column}
                                    </Text></View>)}
                        </>

                    ))}
                </View>
                <FlatList
                    data={sortedData.slice(from, to)}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => index.toString()}
                />
            </View>
            {data.length > itemsPerPage &&
                <View style={styles.paginationFooter}>
                    <TouchableOpacity style={styles.paginationButton} disabled={page === 0} onPress={() => setPage(page - 1)}>
                        <Text>{`< Previous`}</Text>
                    </TouchableOpacity>
                    <Text>{page + 1} of {numberOfPages}</Text>
                    <TouchableOpacity style={styles.paginationButton} disabled={page + 1 === numberOfPages} onPress={() => setPage(page + 1)}>
                        <Text>{`Next >`}</Text>
                    </TouchableOpacity>
                </View>}
        </View>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 30,
    },
    iconStyle: {
        marginRight: 20
    },
    headerTopBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        backgroundColor: "white",
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 0.1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderColor: "#F8F8F8",
    },
    tableBorder: {
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "#F8F8F8",
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    headerTopBarText: {
        color: "#000000",
        fontWeight: "bold",
        fontSize: 16,

    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        borderWidth: 0.1,
        backgroundColor: "#ffffff",
        borderColor: "#F8F8F8",
    },
    heading: {
        flex: 1,
        fontWeight: "bold",
        fontSize: 15,
        textTransform: "capitalize",
    },
    row: {
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: 10,
        backgroundColor: "#fff",
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
    },
    cell: {
        fontSize: 15,
        textAlign: 'left',
        flex: 1
    },
    paginationFooter: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5
    },
    paginationButton: {
        backgroundColor: "#ffffff",
        borderColor: "black",
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        borderRadius: 20,
        paddingVertical: 2,
        paddingHorizontal: 12
    },
    inline: {
        flexDirection: "row"
    },
    lastRow: {
        borderWidth: 0.1,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderColor: "#F8F8F8",
    },
    btnStyle: {
        paddingVertical: 5,
        paddingHorizontal: 8,
        color: "white",
        backgroundColor: "#D3D3D3",
        borderRadius: 20
    },
    image: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
    },

});




export default DashboardTable;