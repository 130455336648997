import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const NoDataMessage = ({ message }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.message}>{message || 'No data available.'}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    fontSize: 18,
    color: 'gray',
  },
});

export default NoDataMessage;
