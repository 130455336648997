import React, { useContext, useState } from 'react'
import { Text, View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native'
import FormPicker from './FormPicker'
import axios from 'axios';
import fileDownload from 'js-file-download';
import LoadingOverlay from './LoadingOverlay';
import { generateStoreHubApiToken } from '../utils/tokenGenerator';
import { STORE_HUB_API_URL } from "@env"


export const FileDownload = () => {
    const baseUrl = STORE_HUB_API_URL || "";
    console.log(STORE_HUB_API_URL)
    const [fileType, setFileType] = useState("COMBINED")
    const [loading, setLoading] = useState(false)
    const handleDownloadPress = async () => {
        setLoading(true)
        let sfsEligible = null;
        switch (fileType) {
            case "EXCLUSION": {
                sfsEligible = false;
                break;
            };
            case "INCLUSION": {
                sfsEligible = true;
                break;
            };
            default: {
                sfsEligible = null
            }
        }
        const params = {
            sfsEligible
        };
        const apiToken = generateStoreHubApiToken();
        const { data } = await axios.get(`${baseUrl}/products/sfs-eligibility-csv`, {
            params: params,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiToken}`,
            }
        });
        setLoading(false)
        fileDownload(data, `${fileType}.csv`);
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <View>
                <View style={styles.fileContainer}>
                    <Text style={styles.downloadText}>Ship From Store Eligibility File Download</Text>
                    <View style={styles.downloadContainer}>
                        <FormPicker
                            value={fileType}
                            options={[
                                { label: 'EXCLUSION', value: "EXCLUSION" },
                                { label: 'INCLUSION', value: "INCLUSION" },
                                { label: 'COMBINED', value: "COMBINED" },
                            ]}
                            onChange={(value) => setFileType(value)}
                        />
                        <TouchableOpacity
                            style={[styles.button, styles.buttonOpen]}
                            onPress={() => handleDownloadPress()}>
                            <Text style={styles.textStyle}>Download</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    fileContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        alignItems: "center",
        padding: 30,
        borderWidth: 0.1,
        borderColor: "#F8F8F8",
        // marginHorizontal: 20,
        // borderRadius: 10,
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        // Shadow properties for Android
        elevation: 5,
    },
    downloadText:{
       fontWeight:"500",
       fontSize:18,
    },
    loader: {
        marginLeft: 10,
    },
    downloadContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        columnGap: 70
    },
    button: {
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal:20,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: 'black',
    },
    textStyle: {
        color: 'white',
        fontWeight: '500',
        textAlign: 'center',
    }
});