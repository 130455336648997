import React, { useEffect, useReducer, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Platform, ScrollView } from 'react-native';
import DashboardCard from '../components/DashboardCard';
import DashboardTable from '../components/DashboardTable';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import './dashboardDatePicker.css'
import { DashboardReducer, initialState } from '../reducers/DashboardReducer';
import { useContext } from 'react';
import AuthContext from '../hooks/AuthContext';
import AlertModal from '../components/AlertModal';


const Dashboard = ({ navigation, route }) => {
  const { accessToken: apiToken, signOut, setGlobalError } = useContext(AuthContext);
  const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1); // Set to yesterday
    date.setHours(23, 30, 0, 0); // Set time to 11:30 PM
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setHours(23, 30, 0, 0); // Set time to 11:30 PM
    return date;
  });
  const [state, dispatch] = useReducer(DashboardReducer, initialState)
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const today = new Date();

  useEffect(() => {
    const startDateUTC = convertToUTCFormat(startDate);
    const endDateUTC = convertToUTCFormat(endDate)
    fetchOrderCountSummary(startDateUTC, endDateUTC);
    fetchAllStores(startDateUTC, endDateUTC);
    fetchShortPickDetails(startDateUTC, endDateUTC);
    fetchOrdersByStatus(startDateUTC, endDateUTC, "CREATED");
    fetchOrdersByStatus(startDateUTC, endDateUTC, "UNFULFILLED,UNFULFILLED_DONE")
  }, [])

  const convertToUTCFormat = (date) => {
    const utcString = date.toUTCString();
    const convertedUtcDate = new Date(utcString);
    // Converting to ISO string and manipulating it to the desired format
    const isoString = date.toISOString();
    const formattedDate = isoString.replace('T', ' ').substring(0, 19);
    return formattedDate
  }


  const fetchOrdersByStatus = async (startDate, endDate, orderStatus) => {
    const { data: { result, status } } = await axios.get(`${baseUrl}/api/orders-count-by-status?startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })

    if (status == 200) {
      const orderStatusInfo = orderStatus.split(',')
      const orderData = result
      dispatch({ type: orderStatusInfo[0], orderData })
    }
  }

  const fetchOrderCountSummary = async (startDate, endDate) => {
    const { data: { result, status } } = await axios.get(`${baseUrl}/api/orders-count?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })
    let newOrderCount = 0;
    let unfulfilledOrderCount = 0;
    let readyOrderCount = 0;
    let inProgressOrderCount = 0;
    const newOrderStatusList = ['CREATED'];
    const unfulfilledOrdersList = ['UNFULFILLED_DONE', 'UNFULFILLED']
    const readyOrdersList = ['FULFILLED_DONE', 'PARTIALLY_FULFILLED_DONE', 'PARTIALLY_FULFILLED', 'FULFILLED']
    const inProgressOrdersList = ['PACKING_COMPLETED', 'NOT_PICKED', 'PACKING_INPROGRESS', 'INPROGRESS', 'PICKING_COMPLETED']

    const storeOrderCount = result
    storeOrderCount.orderStatusCountList.forEach(order => {
      if (newOrderStatusList.includes(order.orderStatus)) {
        newOrderCount += order.orderCount;
      } else if (unfulfilledOrdersList.includes(order.orderStatus)) {
        unfulfilledOrderCount += order.orderCount;
      } else if (readyOrdersList.includes(order.orderStatus)) {
        readyOrderCount += order.orderCount;
      } else if (inProgressOrdersList.includes(order.orderStatus)) {
        inProgressOrderCount += order.orderCount;
      }
    });
    dispatch({ type: 'ORDER_SUMMARY', newOrderCount, unfulfilledOrderCount, readyOrderCount, inProgressOrderCount })
  }

  const fetchAllStores = async () => {
    const { data: { result, status } } = await axios.get(`${baseUrl}/api/stores-list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })
    const storeInfo = result
    const categorizedStores = storeInfo.reduce((acc, item) => {
      const storeObject = {
        storeName: item.storeName,
        storeNumber: item.storeNumber,
      };
      if (item.sfsEnable === "Y") {
        storeObject.status = "Enabled";
        acc.enabledStores.push(storeObject);
      } else { //Remove Disabled
        if (item.sfsEnable === "N") {
          storeObject.status = "Disabled";
          acc.disabledStores.push(storeObject);
        }
      }
      return acc;
    }, { enabledStores: [], disabledStores: [] });

    const neverTurnedOnStores = storeInfo
      .filter(item => item.storeTurnOnTime === null && item.sfsEnable === "N")
      .map(item => ({
        storeName: item.storeName,
        storeNumber: item.storeNumber,
        status: "Temporary/Never Turned On"
      }));

    const limitExceededStores = storeInfo
      .filter(item => item.storeTurnOnTime !== null && item.sfsEnable === "N")
      .map(item => ({
        storeName: item.storeName,
        storeNumber: item.storeNumber,
        status: "Order Limit Hit -Turned Off"
      }));

    dispatch({
      type: 'STORE_SUMMARY',
      enabledStores: categorizedStores.enabledStores,
      disabledStores: categorizedStores.disabledStores,
      neverTurnedOnStores: neverTurnedOnStores,
      limitExceededStores: limitExceededStores
    })
  }

  const formatLocalTime = (utcTimestamp) => {
    // Parse the UTC timestamp to a Date object (assumed to be in UTC)
    const utcDate = new Date(utcTimestamp + 'Z'); // 'Z' indicates UTC time

    // Options for formatting the date
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    // Format the local time to the desired string format using the browser's local timezone
    const formattedTime = utcDate.toLocaleString('en-US', options).replace('at', '');
    return formattedTime;
  }

  const fetchShortPickDetails = async (startDate, endDate) => {
    const { data: { result, status } } = await axios.get(`${baseUrl}/api/orders-short-pick?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
    })
    if (status == 200) {
      const shortPickInfo = result
      const convertedShortPickInfo = shortPickInfo.map(item => ({
        ...item,
        shortPickedDate: formatLocalTime(item.shortPickedDate)
      }));
      dispatch({ type: 'SHORTPICK_INFO', shortPickInfo: convertedShortPickInfo })
    }
  }

  const handleSubmit = () => {
    if (startDate > endDate) {
      setModalMessage('Start date cannot be later than end date!');
      setModalVisible(true);
      return;
    }

    const startDateUTC = convertToUTCFormat(startDate);
    const endDateUTC = convertToUTCFormat(endDate)
    fetchOrderCountSummary(startDateUTC, endDateUTC);
    fetchAllStores(startDateUTC, endDateUTC);
    fetchShortPickDetails(startDateUTC, endDateUTC);
    fetchOrdersByStatus(startDateUTC, endDateUTC, "CREATED");
    fetchOrdersByStatus(startDateUTC, endDateUTC, "UNFULFILLED,UNFULFILLED_DONE")
  }

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <View style={styles.outerContainer}>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.dateContainer}>
          <DatePicker
            placeholderText='Start Date and Time'
            portalId="root-portal-calender"
            // isClearable
            showTimeSelect
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            maxDate={endDate}
            dateFormat="MMMM d, yyyy h:mm aa"
          />
          <DatePicker
            placeholderText='Start Date and Time'
            portalId="root-portal-calender"
            // isClearable
            showTimeSelect
            selected={endDate}
            minDate={startDate}
            maxDate={today}
            onChange={(date) => setEndDate(date)}
            dateFormat="MMMM d, yyyy h:mm aa"
          />
          <TouchableOpacity onPress={handleSubmit}>
            <Text style={styles.submitButton} >Submit</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.cardContainer}>
          <DashboardCard
            orderCount={state.newOrders}
            content="New Orders" />
          <DashboardCard orderCount={state.inProgressOrders} content="Inprogress Orders" />
          <DashboardCard orderCount={state.readyOrders} content="Ready Orders" />
          <DashboardCard orderCount={state.unfullfilledOrders} content="Unfullfilled Orders" />
        </View>
        <View style={styles.tableContainer}>
          <DashboardTable tableName={"Orders in new status by store"} data={state.createdOrdersList} isSortable={true} />
          <DashboardTable tableName={"Orders that are unfulfilled by store"} data={state.unfullfilledOrdersList} isSortable={true} />
        </View>
        <View>
          <DashboardTable tableName={"Short picked items list by store"} data={state.shortPickData} isSortable={true}/>
        </View>
        <View style={styles.tableContainer}>
          <DashboardTable tableName={"Enabled Stores"} data={state.enabledStores} isSortable={true} />
          <DashboardTable tableName={"Stores That Hit Order Limit and Were Turned Off"} data={state.limitExceededStores} isSortable={true} />
          <DashboardTable tableName={"Temporarily Turned Off/ Never Turned On Stores"} data={state.neverTurnedOnStores} isSortable={true} />
        </View>
        <AlertModal
        visible={isModalVisible}
        message={modalMessage}
        onClose={closeModal}
      />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: '#F6F6F8',
  },
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: '#F6F6F8',
  },
  dateContainer: {
    flexDirection: 'row', // Arrange children horizontally
    justifyContent: 'center', // Space evenly between children
    gap: 40
  },
  cardContainer: {
    flexDirection: 'row', // Arrange children horizontally
    // justifyContent: 'space-evenly', // Space evenly between children
    gap: 20,
    marginTop: 10
  },
  tableContainer: {

    flexDirection: 'row', // Arrange children horizontally
    justifyContent: 'space-between', // Space evenly between children
  },
  submitButton: {
    color: "white",
    backgroundColor: "black",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20
  }
});

export default Dashboard;