import React, { useState, useRef, useEffect, useContext } from 'react';
import { ScrollView, Pressable, StyleSheet, Text, View, CheckBox as NativeCheckBox } from 'react-native';
import RegularHours from '../components/RegularHours';
import SpecialHours from '../components/SpecialHours';
import FieldSet from '../components/FieldSet';
import { useNavigation } from '@react-navigation/native';
import FormInput from '../components/FormInput';
import FormPicker from '../components/FormPicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useAuthenticatedFetch from "../hooks/useAuthenticatedRequest"
import axios from 'axios';
import './customDatePicker.css';
import ConfirmationModal from '../components/ConfirmationModal';
import LoadingOverlay from '../components/LoadingOverlay';
import Notification from '../components/Notification';
import AuthContext from '../hooks/AuthContext';
import StoreScheduler from '../components/StoreScheduler';
import AlertModal from '../components/AlertModal';


const usStates = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'CZ', value: 'CZ' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'GU', value: 'GU' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'PR', value: 'PR' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'VI', value: 'VI' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' }
]

const canadaStates = [
  { label: 'AB', value: 'AB' },
  { label: 'BC', value: 'BC' },
  { label: 'MB', value: 'MB' },
  { label: 'NB', value: 'NB' },
  { label: 'NL', value: 'NL' },
  { label: 'NT', value: 'NT' },
  { label: 'NS', value: 'NS' },
  { label: 'NU', value: 'NU' },
  { label: 'ON', value: 'ON' },
  { label: 'PE', value: 'PE' },
  { label: 'QC', value: 'QC' },
  { label: 'SK', value: 'SK' },
  { label: 'YT', value: 'YT' },
]





const StoreScreen = ({ route }) => {
  const { userName, key } = useContext(AuthContext);
  const { storeData, isEdit } = route.params;
  const navigation = useNavigation();
  const scrollViewRef = useRef(null);

  const InitialRegularHours = {
    "Monday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Tuesday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Wednesday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Thursday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Friday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Saturday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    },
    "Sunday": {
      "open_at": '10:00 AM',
      "close_at": '07:00 PM'
    }
  };

  const InitialSpecialHours = [
    {
      "date": "",
      "end_date": "",
      "open_at": "",
      "close_at": "",
      "reason": "",
      "comments": ""
    }
  ];

  const initialFeatureData = [
    {
      "turnOffTime": "",
      "type": "SFS",
      "enabled": "",
      "immediate": true,
      "turnOnTime": ""
    },
    {
      "turnOffTime": "",
      "type": "BOPIS",
      "enabled": false,
      "immediate": true,
      "turnOnTime": ""
    }
  ]


  const initialStoreDetails = {
    basic: {
      store_id: storeData?.basic?.store_id || "",
      store_number: storeData?.basic?.store_number || "",
      store_name: storeData?.basic?.store_name || "",
      email_address: storeData?.basic?.email_address || "",
      shopify_location_id: storeData?.basic?.shopify_location_id || "",
    },
    additional: {
      store_type: storeData?.additional?.store_type || "regular",
      is_active: storeData?.additional?.is_active || false,
      ship_from_store_enabled: storeData?.additional?.ship_from_store_enabled || false,
      curbside_pickup_enabled: storeData?.additional?.curbside_pickup_enabled || false,
      buy_online_pickup_in_store_enabled: storeData?.additional?.buy_online_pickup_in_store_enabled || false,
      find_in_store_enabled: storeData?.additional?.find_in_store_enabled || false,
      does_store_have_studio: storeData?.additional?.does_store_have_studio || false,
      does_store_have_gym: storeData?.additional?.does_store_have_gym || false,
      start_date: storeData?.additional?.start_date || "",
      end_date: storeData?.additional?.end_date || "",
      fulfillmentType: storeData?.additional?.fulfillmentType || initialFeatureData
    },
    location: {
      longitude: storeData?.location?.longitude || "",
      latitude: storeData?.location?.latitude || "",
      address_line1: storeData?.location?.address_line1 || "",
      address_line2: storeData?.location?.address_line2 || "",
      city: storeData?.location?.city || "",
      state: storeData?.location?.state || "",
      district: storeData?.location?.district || "",
      region: storeData?.location?.region || "",
      country: storeData?.location?.country || '',
      zipcode: storeData?.location?.zipcode || "",
      phone_number: storeData?.location?.phone_number || "",
      mall_name: storeData?.location?.mall_name || "",
      timezone: storeData?.location?.timezone || "America/Los_Angeles"
    },
    regular_hours: storeData?.regular_hours || InitialRegularHours,
    special_hours: storeData?.special_hours || InitialSpecialHours
  };
  let updatedStoreData = storeData;
  if (!storeData?.additional?.fulfillmentType) {
    updatedStoreData = {
      ...storeData,
      additional: {
        ...storeData?.additional,
        fulfillmentType: initialFeatureData
      }
    }
  }
  const DefaultState = isEdit ? updatedStoreData : initialStoreDetails
  const [storeDetails, setStoreDetails] = useState(DefaultState);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [confirmGoBackWithoutSave, setConfirmGoBackWithoutSave] = useState(false);

  const [validateFail, setValidateFail] = useState(false);
  const [action, setAction] = useState('create');
  // Listing network call associated with Component
  const { isLoading, error: fetchError, data: fetchData, triggerNetworkCall } = useAuthenticatedFetch('/api/store', 'POST');
  const [locationLoading, setLocationLoading] = useState(false);
  const [googleAPIError, setGoogleAPIError] = useState(null);
  const [specialHourError, setSpecialHourError] = useState(false);
  const [validationMessage, setValidationMessage] = useState("Mandatory Fields Missing");
  const [isModalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (fetchData || fetchError) {
      setModalVisibility(true)
    }
  }, [
    fetchData, fetchError
  ]);

  // Implement scroll functionality
  useEffect(() => {
    const firstErrorField = Object.keys(error).find((field) => error[field]);
    if (firstErrorField) {
      let parentHeight = 0;
      scrollViewRef.current.measure((x, y, width, height, pageX, pageY) => {
        parentHeight = height;
      });
      if (firstErrorField && formFieldRef[firstErrorField]) {
        const fieldRef = formFieldRef[firstErrorField];
        fieldRef.current.measure((x, y, width, height, pageX, pageY) => {
          scrollViewRef.current.scrollTo({ y: parentHeight - (pageY * -1), animated: true });
        });
      }
    }
  }, [validateFail, error])

  const formFieldRef = {
    //store_id ommitted since its not rendered in DOM
    store_number: useRef(null),
    store_name: useRef(null),
    shopify_location_id: useRef(null),
    email_address: useRef(null),
    address_line1: useRef(null),
    city: useRef(null),
    state: useRef(null),
    zipcode: useRef(null),
    country: useRef(null),
    latitude: useRef(null),
    longitude: useRef(null),
  }

  const validateSpecialHour = () => {
    return storeDetails.special_hours.some((item) => {
      const { comments, ...rest } = item;
      const truthySpecialHours = Object.values(rest).every(value => !!value);
      const falsySpecialHours = Object.values(rest).every(value => !value);

      if (truthySpecialHours || falsySpecialHours) {
        setSpecialHourError(false);
        return false
      } else {
        setSpecialHourError(true)
        return true
      }

    });
  }

  const validateScheduler = (scheduler) => {
    for (const schedule of scheduler) {
      if (!schedule.immediate) {
        if (schedule.enabled && schedule.turnOnTime === "") {
          setValidationMessage("Please enter the turn on time")
          setModalVisible(true)
          return false
        }
        else if (!schedule.enabled && schedule.turnOffTime === "") {
          setValidationMessage("Please enter the turn off time")
          setModalVisible(true)
          return false
        }
        else {
          return true
        }
      }
      else {
        return true
      }
    }
  }

  const [error, setError] = useState({
    store_id: null,
    store_number: null,
    store_name: null,
    shopify_location_id: null,
    email_address: null,
    address_line1: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    latitude: null,
    longitude: null,
  })

  // These fields are set to be required. If needed feel free to add more
  const regexMap = {
    basic: {
      store_number: {
        exp: /^\d{5}$/,
        errorMessage: "Length of Store ID should be 5 and not include characters or aplphanumeric characters"
      },
      store_name: {
        exp: /^[A-Za-z0-9\s\.,#-]+$/,
        errorMessage: "Store Name should have minimum 4 characters and dont include alphanumeric characters"
      },
      email_address: {
        exp: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        errorMessage: "Enter a valid email address"
      },
      shopify_location_id: {
        exp: /^\d+$/,
        errorMessage: "Location should be number"
      },
    },
    location: {
      latitude: {
        exp: /^-?\d+(\.\d+)?$/,
        errorMessage: "Enter a valid latitude"
      },
      longitude: {
        exp: /^-?\d+(\.\d+)?$/,
        errorMessage: "Enter a valid longitude"
      },
      address_line1: {
        exp: /^[A-Za-z0-9\s\.,#-]+$/,
        errorMessage: "Enter a valid Address Line"
      },
      city: {
        exp: /^[A-Za-z\s]+$/,
        errorMessage: "Enter a valid City"
      },
      state: {
        exp: /^[A-Za-z\s]+$/,
        errorMessage: "Enter a valid State"
      },
      country: {
        exp: /^[A-Za-z\s]+$/,
        errorMessage: "Enter a valid Country"
      },
      zipcode: {
        exp: storeDetails.location.country !== 'UK' ? /^[a-zA-Z0-9]{5,6}$|^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/ : /^(GIR 0AA|[A-Z]{1,2}\d{1,2} ?\d[A-Z]{2}|[A-Z]{1,2}\d[A-Z] ?\d[A-Z]{2})$/i,
        errorMessage: "Enter a valid zipcode"
      }
    }
  };

  const validateUserInput = (parent, key, value) => {
    // Checking whether field is required and has a regex pattern
    if (regexMap[parent] && regexMap[parent][key] && regexMap[parent][key].exp) {
      if (!regexMap[parent][key].exp.test(value)) {
        setError((prev) => ({
          ...prev,
          [key]: regexMap[parent][key].errorMessage
        }));
      } else {
        setError((prev) => ({
          ...prev,
          [key]: null
        }));
      }
    }
  };


  const validateForm = () => {
    let status = true;

    Object.keys(regexMap).forEach((section) => {
      Object.keys(regexMap[section]).forEach((field) => {
        const value = storeDetails[section][field];
        const { exp, errorMessage } = regexMap[section][field];
        if (section === 'location' && field === 'state' && storeDetails.location.country === 'UK') {
          return; // Skip validation
        }
        if (!value) {
          setError((prev) => ({ ...prev, [field]: errorMessage }));
          status = false;
        } else if (exp && !exp.test(value)) {
          setError((prev) => ({ ...prev, [field]: errorMessage }));
          status = false;
        }
      });
    });

    return status;
  };

  //Update SpecialHourss
  const updateSpecialHours = (index, key, value) => {
    if (value === 'Closed') {
      setStoreDetails((prev) => ({
        ...prev,
        special_hours: prev.special_hours.map((hour, i) =>
          i === index ? { ...hour, open_at: value, close_at: value, reason: "HOLIDAY" } : hour
        )
      }));
    } else {
      setStoreDetails((prev) => ({
        ...prev,
        special_hours: prev.special_hours.map((hour, i) =>
          i === index ? { ...hour, [key]: value } : hour
        )
      }));
    }
  };


  const updateFulfillmentScheduler = (index, key, value) => {
    setStoreDetails((prev) => ({
      ...prev,
      additional: {
        ...prev.additional,
        fulfillmentType: prev.additional.fulfillmentType.map((item, idx) =>
          idx === index ? { ...item, [key]: value } : item
        )
      }
    }));
  };

  const deleteSpecialHour = (indexToDelete) => {
    setStoreDetails((prev) => ({
      ...prev,
      special_hours: prev.special_hours.filter((_, i) => i !== indexToDelete)
    }));
  };

  // Single Handler for all form fields
  const handleOnChange = (parent, key = '', value, period = '') => {
    setValidateFail(false)
    // Validate user input
    if (parent === 'regular_hours') {
      setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        [parent]: {
          ...prevStoreDetails[parent],
          [key]: {
            ...prevStoreDetails[parent][key],
            [period]: value,
          },
        },
      }))
    } else if (parent === 'special_hours') {
      setStoreDetails((prev) => ({
        ...prev,
        [parent]: [...prev[parent], value]
      }))
    }
    else {
      validateUserInput(parent, key, value);
      if (parent === 'location' && key === 'country' && value === 'UK') {
        setStoreDetails((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [key]: value,
            state: ''
          },
        }));
      }
      else {
        setStoreDetails((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [key]: value,
          },
        }));
      }
    }
  }

  // Handling onfocus for TextInput
  const inputFocusHandler = async () => {
    const { address_line1, city, country, zipcode, state } = storeDetails.location;
    if (address_line1 || city || country || zipcode || state) {
      try {
        setLocationLoading(true);
        setGoogleAPIError(null); //Reseting google API error
        const { data: { results } } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address_line1 + city + state + country + zipcode}&key=${key}`);
        const { lat, lng } = results[0].geometry.location;
        const res = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${key}`);
        handleOnChange('location', 'latitude', lat);
        handleOnChange('location', 'longitude', lng);
        handleOnChange('location', 'timezone', res.data.timeZoneId)
      } catch (error) {
        setGoogleAPIError('Please provide correct address, Try again!')
      }
      finally {
        setLocationLoading(false);
      }
    }
  };


  function generate_id() {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 1000);
    const id = timestamp + randomNum;
    return id;
  }

  const handleAddStore = async (event) => {
    setGoogleAPIError(null)
    event.preventDefault();
    let updatedStoreDetails = {
      ...storeDetails,
      additional: {
        ...storeDetails.additional,
        fulfillmentType: storeDetails?.additional?.fulfillmentType.map((fulfillment) => {
          return {
            ...fulfillment,
            immediate: fulfillment?.immediate === "" ? true : fulfillment.immediate,
            enabled: fulfillment?.enabled === "" ? false : fulfillment.enabled
          };
        })
      }

    }
    let scheduler = updatedStoreDetails?.additional?.fulfillmentType || [];
    if (validateForm() && !validateSpecialHour() && validateScheduler(scheduler)) {
      setValidateFail(false);
      // POST call
      if (updatedStoreDetails.basic.store_id === '') {
        updatedStoreDetails.basic.store_id = generate_id().toString();
      }
      await triggerNetworkCall({ ...updatedStoreDetails, created_by: userName });
    }
    else {
      setValidateFail(!validateFail);
    }
  };

  const handleUpdate = async (event) => {
    setGoogleAPIError(null)
    setAction('update');
    event.preventDefault();
    let updatedStoreDetails = {
      ...storeDetails,
      additional: {
        ...storeDetails.additional,
        fulfillmentType: storeDetails?.additional?.fulfillmentType.map((fulfillment) => {
          return {
            ...fulfillment,
            immediate: fulfillment?.immediate === "" ? true : fulfillment.immediate,
            enabled: fulfillment?.enabled === "" ? storeData?.additional?.ship_from_store_enabled : fulfillment.enabled
          };
        })
      }

    }
    let scheduler = updatedStoreDetails?.additional?.fulfillmentType || [];
    if (validateForm() && !validateSpecialHour() && validateScheduler(scheduler)) {
      setValidateFail(false);
      await triggerNetworkCall({ ...updatedStoreDetails, created_by: userName }, '/update');
    }
    else {
      setValidateFail(!validateFail);
    }
  };

  return (
    isLoading ? <LoadingOverlay /> :
      <View style={styles.parent}>
        <ScrollView ref={scrollViewRef} style={styles.container}>
          <View style={styles.spaceWrapper} />
          <FieldSet title="Basic Details" customStyle={{ flexDirection: "row", gap: 20 }}>
            <View style={{ ...styles.basic, flex: 2 }}>
              <FormInput
                placeholder="Store Number"
                value={storeDetails.basic.store_number}
                onChangeText={(value) => handleOnChange('basic', 'store_number', value)}
                error={error.store_number}
                ref={formFieldRef.store_number}
                editable={!isEdit}
              />
              <FormInput
                placeholder="Store Name"
                value={storeDetails.basic.store_name}
                onChangeText={(value) => handleOnChange('basic', 'store_name', value)}
                error={error.store_name}
                ref={formFieldRef.store_name}
              />
              <FormInput
                placeholder="Email Address"
                value={storeDetails.basic.email_address}
                onChangeText={(value) => handleOnChange('basic', 'email_address', value)}
                error={error.email_address}
                ref={formFieldRef.email_address}
              />
              <FormInput
                placeholder="Shopify Location"
                value={storeDetails.basic.shopify_location_id}
                onChangeText={(value) => handleOnChange('basic', 'shopify_location_id', value)}
                error={error.shopify_location_id}
                ref={formFieldRef.shopify_location_id}
              />
              <DatePicker withPortal
                portalId="root-portal-calender"
                placeholderText="Start Date"
                isClearable
                showYearDropdown
                selected={storeDetails.additional.start_date ? new Date(storeDetails.additional.start_date) : null}
                onChange={(value) => handleOnChange('additional', 'start_date', value)} />
              <DatePicker withPortal
                portalId="root-portal-calender"
                placeholderText="End Date"
                isClearable
                showYearDropdown
                selected={storeDetails.additional.end_date ? new Date(storeDetails.additional.end_date) : null}
                onChange={(value) => handleOnChange('additional', 'end_date', value)} />
            </View>
            <View style={styles.basic}>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.is_active}
                  onValueChange={(value) => handleOnChange('additional', 'is_active', value)}
                />
                <Text>Is Active</Text>
              </View>
              {/* <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.ship_from_store_enabled}
                  onValueChange={(value) => handleOnChange('additional', 'ship_from_store_enabled', value)}
                />
                <Text>Allow Ship from Store</Text>
              </View> */}
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.curbside_pickup_enabled}
                  onValueChange={(value) => handleOnChange('additional', 'curbside_pickup_enabled', value)}
                />
                <Text>Allow Curbside Pickup</Text>
              </View>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.buy_online_pickup_in_store_enabled}
                  onValueChange={(value) => handleOnChange('additional', 'buy_online_pickup_in_store_enabled', value)}
                />
                <Text>Allow Buy Online Pickup in Store</Text>
              </View>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.find_in_store_enabled}
                  onValueChange={(value) => handleOnChange('additional', 'find_in_store_enabled', value)}
                />
                <Text>Allow Find in Store</Text>
              </View>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.does_store_have_gym}
                  onValueChange={(value) => handleOnChange('additional', 'does_store_have_gym', value)}
                />
                <Text>Have Gym in Store</Text>
              </View>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.does_store_have_studio}
                  onValueChange={(value) => handleOnChange('additional', 'does_store_have_studio', value)}
                />
                <Text>Have Studio in Store</Text>
              </View>
              <View style={styles.rowise}>
                <NativeCheckBox
                  value={storeDetails.additional.store_type === 'outlet'}
                  onValueChange={(value) => handleOnChange('additional', 'store_type', value ? "outlet" : "regular")}
                />
                <Text>Is Outlet</Text>
              </View>
            </View>
          </FieldSet>
          <View style={styles.spaceWrapper} />
          <View style={styles.spaceWrapper} />
          <FieldSet title="Location Details">
            <FormInput
              placeholder="Address Line 1"
              value={storeDetails.location.address_line1}
              onChangeText={(value) => handleOnChange('location', 'address_line1', value)}
              error={error.address_line1}
              ref={formFieldRef.address_line1}
            />
            <FormInput
              placeholder="Address Line 2"
              value={storeDetails.location.address_line2}
              onChangeText={(value) => handleOnChange('location', 'address_line2', value)}
            />
            <FormInput
              placeholder="City"
              value={storeDetails.location.city}
              onChangeText={(value) => handleOnChange('location', 'city', value)}
              error={error.city}
              ref={formFieldRef.city}
            />
            <FormPicker
              value={storeDetails.location.country}
              placeholder="Country"
              error={error.country}
              ref={formFieldRef.country}
              options={[
                { label: 'CA', value: 'CA' },
                { label: 'US', value: 'US' },
                { label: 'UK', value: 'UK' },
              ]}
              onChange={(value) => handleOnChange('location', 'country', value)}
            />
            <FormPicker
              value={storeDetails.location.state}
              isEnabled={storeDetails.location.country !== 'UK'}
              placeholder="State"
              error={storeDetails.location.country !== 'UK' ? error.state : null}
              ref={formFieldRef.state}
              options={storeDetails.location.country === 'US' ?
                usStates : storeDetails.location.country === 'CA' ?
                  canadaStates : []}
              onChange={(value) => handleOnChange('location', 'state', value)}
            />
            <FormPicker
              value={storeDetails.location.district}
              placeholder="District"
              options={[
                { label: 'West', value: 'West' },
                { label: 'Central', value: 'Central' },
                { label: 'East', value: 'East' },
                { label: 'Flagship', value: 'Flagship' },
                { label: 'Canada', value: 'Canada' },
                { label: 'Midwest', value: 'Midwest' },
                { label: 'Northeast', value: 'Northeast' },
                { label: 'Northwest', value: 'Northwest' },
                { label: 'NYC Boston', value: 'NYC Boston' },
                { label: 'SoCal', value: 'SoCal' },
                { label: 'Southeast', value: 'Southeast' },
                { label: 'Texas Arizona', value: 'Texas Arizona' },
                { label: 'UK', value: 'UK' },
              ]}
              onChange={(value) => handleOnChange('location', 'district', value)}
            />
            <FormPicker
              value={storeDetails.location.region}
              placeholder="Region"
              options={[
                { label: 'Canada', value: 'Canada' },
                { label: 'US', value: 'US' },
                { label: 'UK', value: 'UK' },
              ]}
              onChange={(value) => handleOnChange('location', 'region', value)}
            />
            <FormInput
              placeholder="ZipCode"
              value={storeDetails.location.zipcode}
              onChangeText={(value) => handleOnChange('location', 'zipcode', value)}
              error={error.zipcode}
              ref={formFieldRef.zipcode}
            />
            <FormInput
              placeholder="Phone Number"
              value={storeDetails.location.phone_number}
              onChangeText={(value) => handleOnChange('location', 'phone_number', value)}
            />
            <FormInput
              placeholder="Mall Name"
              value={storeDetails.location.mall_name}
              onChangeText={(value) => handleOnChange('location', 'mall_name', value)}
            />
            <FormInput
              placeholder="Latitude"
              value={storeDetails.location.latitude}
              // onChangeText={(value) =>handleOnChange('location','latitude', value)}
              error={error.latitude}
              focusHandler={inputFocusHandler}
              ref={formFieldRef.latitude}
              loading={locationLoading}
              isEditable={false}
            />
            <FormInput
              placeholder="Longitude"
              value={storeDetails.location.longitude}
              // onChangeText={(value) =>handleOnChange('location','longitude', value)}
              error={error.longitude}
              focusHandler={inputFocusHandler}
              ref={formFieldRef.longitude}
              loading={locationLoading}
              isEditable={false}
            />
            <FormInput
              placeholder="Timezone"
              value={storeDetails.location.timezone}
              // onChangeText={(value) =>handleOnChange('location','timezone', value)}
              focusHandler={inputFocusHandler}
              loading={locationLoading}
              isEditable={false}
            />
          </FieldSet>
          <View style={styles.spaceWrapper} />
          <View style={styles.spaceWrapper} />
          <FieldSet title="Regular Hours">
            <RegularHours hours={storeDetails.regular_hours} setHours={handleOnChange} />
          </FieldSet>
          <View style={styles.spaceWrapper} />
          <View style={styles.spaceWrapper} />
          <FieldSet title="Store Scheduler">
            <StoreScheduler
              fulfillmentTypes={storeDetails.additional.fulfillmentType}
              updateFulfillmentScheduler={updateFulfillmentScheduler}
              isSFS={storeDetails.additional.ship_from_store_enabled} />
          </FieldSet>
          <View style={styles.spaceWrapper} />
          <View style={styles.spaceWrapper} />
          <FieldSet title="Special Hours">
            <SpecialHours hours={storeDetails.special_hours} setHours={handleOnChange} updateSpecialHour={updateSpecialHours} deleteSpecialHour={deleteSpecialHour} error={specialHourError} />
          </FieldSet>
          <View style={styles.spaceWrapper} />
          <View style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
            <Pressable style={styles.button} onPress={() => {
              setConfirmGoBackWithoutSave(true)
            }}>
              <Text style={styles.text}>GO BACK</Text>
            </Pressable>
            {
              isEdit ?
                <Pressable style={styles.button} onPress={handleUpdate}>
                  <Text style={styles.text}>UPDATE</Text>
                </Pressable> :
                <Pressable style={styles.button} onPress={handleAddStore}>
                  <Text style={styles.text} >ADD</Text>
                </Pressable>
            }
          </View>
        </ScrollView>
        <ConfirmationModal
          isVisible={modalVisibility}
          message={(fetchError) ? fetchError?.message : `Store ${isEdit ? 'Updated' : 'Created'} successfully !!`}
          onClose={() => {
            if (fetchError) {
              setModalVisibility(false);
            } else {
              navigation.navigate('Home', { saved: !isEdit })
            }
          }}
          onConfirm={() => {
            if (fetchError) {
              setModalVisibility(false);
            } else {
              navigation.navigate('Home', { saved: !isEdit })
            }
          }}
          notify
          {...(fetchError ? { error: true } : { success: true })}
        />
        <ConfirmationModal
          isVisible={confirmGoBackWithoutSave}
          message={"Confirm leave without Save/Update"}
          onClose={() => {
            setConfirmGoBackWithoutSave(false)
          }}
          onConfirm={() => {
            setConfirmGoBackWithoutSave(false);
            navigation.goBack();
          }}
        />
        {googleAPIError && <Notification type="error" message={googleAPIError} />}
        <AlertModal
          visible={isModalVisible}
          message={validationMessage}
          onClose={closeModal}
        />
      </View>
  );
};

const styles = StyleSheet.create({
  parent: {
    flex: 1
  },
  button: {
    backgroundColor: 'gray',
    height: '40px',
    borderRadius: '5px',
    minWidth: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowise: {
    flexDirection: 'row',
    gap: 10,
    alignItems: "center"
  },
  text: {
    color: '#fff',
    fontSize: '16px',
  },
  radio: {
    flexDirection: 'row',
    gap: 20
  },
  basic: {
    flex: 1,
    gap: 10
  },
  container: {
    padding: 16,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
  },
  spaceWrapper: {
    marginBottom: 10,
  },
  section: {
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#c0c0c0',
    padding: 10,
    marginBottom: 20,
  },
});

export default StoreScreen;
