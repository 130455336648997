import * as React from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AuthContext from '../hooks/AuthContext';
import SplashScreen from './SplashScreen';
import HomeScreen from './HomeScreen';
import StoreScreen from './StoreScreen'
import SignInScreen from './SignInScreen';
import IconButton from '../components/IconButton';
import SearchStores from './SearchScreen';
import sessionStorageUtil from '../hooks/SessionStorageUtils';
import OrderStats from './OrderStats';
import Dashboard from './Dashboard';
import { FileUploadDownload } from './FileUploadDownload';
import SearchableDataTable from '../screen/SearchableDataTable';

const Stack = createNativeStackNavigator();

export default function Main({ navigation }) {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            username: action.user,
            role: action.role,
            key: action.key
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
        case 'ERROR':
          return {
            ...prevState,
            error: action.error
          }
        case 'MODAL_OPEN':
          return {
            ...prevState,
            modalOpen: action.flag
          }
      }
    },
    {
      isLoading: false,
      isSignout: false,
      userToken: sessionStorageUtil.getItem() || null,
      error: null,
      modalOpen: false,
      username: sessionStorageUtil.getItem('username') || '',
      role: sessionStorageUtil.getItem('role') || '',
      key: sessionStorageUtil.getItem('key') || ''
    }
  );

  const authContext = {
    signIn: async (data) => {
      try {
        console.log('Logging In....');
        const HOST = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';
        const response = await fetch(HOST + '/api/authenticate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data), // Assuming `data` contains username and password
        });

        if (response.ok) {
          const { token, username, role, key } = await response.json();
          sessionStorageUtil.setItem(token);
          sessionStorageUtil.setItem(key, 'google_api_key');
          sessionStorageUtil.setItem(username, 'username');
          sessionStorageUtil.setItem(role, 'role');
          dispatch({ type: 'SIGN_IN', token: token, user: username, role: role, key: key });
        } else {
          // Handle sign-in failure, you can show an error message to the user
        }
      } catch (error) {
        console.error('An error occurred during sign-in:', error);
      }
    },
    signOut: () => {
      sessionStorageUtil.removeItem();
      dispatch({ type: 'SIGN_OUT' })
    },
    signUp: async (data) => {
      // Backend signup endpoint if needed
      // dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
    },
    setGlobalError: (error) => {
      dispatch({ type: 'ERROR', error })
    },
    setModalOpen: (flag) => {
      console.log("Flaaag=", flag);
      dispatch({ type: 'MODAL_OPEN', flag })
    },
    accessToken: state.userToken,
    globalError: state.error,
    modalOpen: state.modalOpen,
    userName: state.username,
    role: state.role,
    key: state.key
  };

  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      gap: 12
    }
  })

  return (
    <AuthContext.Provider value={authContext}>
      <NavigationContainer>
        <Stack.Navigator>
          {state.isLoading ? (
            // We haven't finished checking for the token yet
            <Stack.Screen name="Splash" component={SplashScreen} />
          ) : state.userToken == null ? (
            // No token found, user isn't signed in
            <Stack.Group>
               <Stack.Screen
                name="SignIn"
                component={SignInScreen}
                options={{
                  title: 'Sign in',
                  // When logging out, a pop animation feels intuitive
                  animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                }}
              /> 
            </Stack.Group>
          ) : (
            // User is signed in <Button title="Sign out" onPress={() => dispatch({ type: 'SIGN_OUT' })} />
            <Stack.Group>
              <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={({ navigation }) => ({
                  headerTitle: () => (
                    <View style={{ flexDirection: 'row' }}><TouchableOpacity onPress={() => window.location.reload()}>
                      <Text style={{ textDecorationLine: 'underline', fontSize: 18, marginRight: 10 }}>Home</Text>
                    </TouchableOpacity>
                      <TouchableOpacity onPress={() => navigation.navigate('OrderStats')}>
                        <Text style={{ fontSize: 18, marginLeft: 15 }}>OrderStatistics</Text>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
                        <Text style={{ fontSize: 18, marginLeft: 25 }}>Dashboard</Text>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => navigation.navigate('ProductExclusion')}>
                        <Text style={{ fontSize: 18, marginLeft: 25 }}>
                          SFS - Product Exclusion
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ),
                  headerRight: () => (
                    <View style={styles.header}>
                      <IconButton icon="account" label="Account" onPress={() => dispatch({ type: 'MODAL_OPEN', action: true })} />
                      <IconButton icon="logout" label="Sign out" onPress={() => dispatch({ type: 'SIGN_OUT' })} />
                    </View>
                  ),
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="OrderStats"
                component={OrderStats}
                options={({ navigation }) => ({
                  headerTitle: 'Order Statistics',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="Dashboard"
                component={Dashboard}
                options={({ navigation }) => ({
                  headerTitle: 'Dashboard',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="ProductExclusion"
                component={FileUploadDownload}
                options={({ navigation }) => ({
                  headerTitle: 'SFS - Product Exclusion',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="SearchableDataTable"
                component={SearchableDataTable}
                options={({ navigation }) => ({
                  headerTitle: 'Search sku',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="Store"
                component={StoreScreen}
                options={({ navigation }) => ({
                  headerTitle: 'Store',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }
                })}
              />
              <Stack.Screen
                name="SearchStore"
                component={SearchStores}
                options={({ navigation }) => ({
                  headerTitle: 'Store Results',
                  headerStyle: {
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }
                })}
              />
            </Stack.Group>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </AuthContext.Provider >
  );
}
