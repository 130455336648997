

const sampleData2 = [
  {
    sku_id: '12345',
    product_name: 'Product A',
    upc: '1234',
    style: 'Style 1',
    color: 'Red',
    size: 'M',
    current_status: true,  // should map to sfs_eligible
    include_date: '2024-08-06',
    exclude_date: '',
  },
  {
    sku_id: '61230',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: false,
    include_date: '',
    exclude_date: '2024-08-06',

  },
  {
    sku_id: '17080',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: true,
    include_date: '',
    exclude_date: '',

  },
  {
    sku_id: '69080',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: false,
    include_date: '',
    exclude_date: '',

  },
  {
    sku_id: '26890',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: true,
    include_date: '',
    exclude_date: '',

  },
  {
    sku_id: '34890',
    product_name: 'Product B',
    upc: '9876',
    style: 'Style 2',
    color: 'Blue',
    size: 'L',
    current_status: false,
    include_date: '',
    exclude_date: '',

  },
];

import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet, ScrollView, TouchableOpacity, Image, Dimensions } from 'react-native';
import { Parser } from '@json2csv/plainjs';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import './editDatePickerStyles.css';
import FormPicker from './FormPicker';

const { height, width } = Dimensions.get('window');

const EditableTable = ({ tableData = [] }) => {


  useEffect(() => {
    const updatedInitialData = tableData.map((data) => {
      return {
        ...data,
        changed_status: "",
        isEdited: false,
      }
    })
    setData(updatedInitialData)

  }, [tableData])


  const [data, setData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState({ type: '', index: -1 });
  const [selectAllInclude, setSelectAllInclude] = useState(false);
  const [selectAllExclude, setSelectAllExclude] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [page, setPage] = useState(0);
  const itemsPerPage = 6;
  const [checked, setChecked] = useState(false)


  const skuTableHeaders = ["skuId",
    "productName",
    "upc",
    "style",
    "color",
    "current_status",
    "changed_status",
    "include_date",
    "exclude_date",
  ]

  // const tableHeaders = data.length > 0 ? Object.keys(data[0]) : [];
  const tableHeaders = skuTableHeaders;




  const sortedData = useMemo(() => {
    if (!sortColumn) return data;
    const columnIndex = tableHeaders.indexOf(sortColumn);
    return [...data].sort((a, b) => {
      if (a[tableHeaders[columnIndex]] < b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? -1 : 1;
      if (a[tableHeaders[columnIndex]] > b[tableHeaders[columnIndex]]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, data.length);
  const numberOfPages = Math.ceil(data.length / itemsPerPage);

  const handleCheckboxChange = (index, field, value) => {
    const newData = [...data];
    const actualIndex = page * itemsPerPage + index;
    if (actualIndex < newData.length) {
      newData[actualIndex][field] = value;
      newData[actualIndex]["isEdited"] = true;
      if (value !== "") {
        newData[actualIndex]["sfsEligibleOnDate"] = null
        newData[actualIndex]["sfsEligibleOffDate"] = null
      }
      console.log(newData)
      setData(newData);
    }
  };

  const handleSelectAllChange = (field, value) => {
    const newData = data.map(item => ({
      ...item,
      [field]: value
    }));
    setData(newData);
    if (field === 'include') {
      setSelectAllInclude(value);
    } else {
      setSelectAllExclude(value);
    }
  };

  const handleDateChange = (index, field, event, selectedDate) => {
    setData(prevData => {
      const actualIndex = page * itemsPerPage + index;
      const newData = [...prevData];
      if (selectedDate) {
        newData[actualIndex] = {
          ...newData[actualIndex],
          [field]: formatDate(event)
        };
      } else {
        newData[actualIndex] = {
          ...newData[actualIndex],
          [field]: ''
        };
      }
      newData[actualIndex]["isEdited"] = true;
      return newData;
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const downloadCSV = () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(data);
    fileDownload(csv, 'tableData.csv');
  };

  const handleExcludeAll = () => {
    const newData = data.map((d) => {
      return {
        ...d,
        changed_status: checked
      }
    })
    setData(newData);
    setChecked((checked) => !checked)
  }

  const updateSkus = () => {
    const editedSkus = data.filter((sku) => sku.isEdited === true);
    console.log(editedSkus)
  }

  return (
    <View style={styles.container}>
  <TouchableOpacity onPress={downloadCSV} style={styles.downloadButton}>
    <Image source={require('../../assets/downloadIcon.png')} style={styles.image} />
    <Text style={styles.downloadText}>Download CSV</Text>
  </TouchableOpacity>
  <ScrollView horizontal>
    <View style={styles.table}>
      <View style={styles.header}>
        {tableHeaders.map((header, index) => (
          header !== 'exclude' && header !== 'include' && header !== 'changed_status' ? (
            <TouchableOpacity
              style={styles.headerCell}
              key={index}
              onPress={() => handleSortColumn(header)}
            >
              <Text numberOfLines={4} style={styles.headerText}>
                {header}
                {sortColumn === header ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : ' ↓'}
              </Text>
            </TouchableOpacity>
          ) :
            tableHeaders.includes('changed_status') ? (
              <View style={styles.headerCell} key={index}>
                <Text style={styles.headerText}>changed_status</Text>
                <input
                  type="checkbox"
                  value={checked}
                  onChange={() => handleExcludeAll()}
                />
              </View>
            ) :
              tableHeaders.includes('exclude') ? (
                <View style={styles.headerCell} key={index}>
                  <FormPicker
                    value={selectAllExclude ? "ENABLED" : "DISABLED"}
                    options={[
                      { label: 'Select-Status', value: '' },
                      { label: 'ENABLED', value: 'ENABLED' },
                      { label: 'DISABLED', value: 'DISABLED' }
                    ]}
                    isEnabled={index <= 0}
                    onChange={(value) => handleSelectAllChange('exclude', value === 'ENABLED')}
                  />
                </View>
              ) :
                tableHeaders.includes('include') ? (
                  <View style={styles.headerCell} key={index}>
                    <FormPicker
                      value={selectAllInclude ? "ENABLED" : "DISABLED"}
                      options={[
                        { label: 'Select-Status', value: '' },
                        { label: 'ENABLED', value: 'ENABLED' },
                        { label: 'DISABLED', value: 'DISABLED' }
                      ]}
                      isEnabled={index <= 0}
                      onChange={(value) => handleSelectAllChange('include', value === 'ENABLED')}
                    />
                  </View>
                ) : null
        ))}
      </View>

      <FlatList
        data={sortedData.slice(from, to)}
        renderItem={({ item, index }) => (
          <View key={index} style={styles.row}>
            {tableHeaders.map((header, cellIndex) => (
              header !== 'current_status' && header !== 'changed_status' &&
              header !== 'exclude' && header !== 'include' && header !== 'exclude_date' && header !== 'include_date' && (
                <Text style={styles.cell} key={cellIndex}>
                  {item[header]}
                </Text>
              )
            ))}
            {tableHeaders.includes('current_status') && (
              <View style={styles.cell}>
                <Text>{item.current_status ? "INCLUDED" : "EXCLUDED"}</Text>
              </View>
            )}

            {tableHeaders.includes('changed_status') && (
              <View style={styles.cell}>
                <FormPicker
                  value={item.changed_status}
                  options={[
                    { label: 'Select-Status', value: '' },
                    { label: 'INCLUDED', value: true },
                    { label: 'EXCLUDED', value: false }
                  ]}
                  isEnabled
                  onChange={(value) => handleCheckboxChange(index, 'changed_status', value)}
                />
              </View>
            )}

            {tableHeaders.includes('include_date') && (
              <View style={styles.cell}>
                <DatePicker
                  disabled={item.changed_status !== ""}
                  withPortal
                  portalId="root-portal-calender"
                  showYearDropdown
                  placeholderText="include date"
                  selected={item.sfsEligibleOnDate ? new Date(item.sfsEligibleOnDate) : null}
                  dateFormat="yyyy-MM-dd"
                  onChange={(event, selectedDate) => handleDateChange(index, 'sfsEligibleOnDate', event, selectedDate)}
                />
              </View>
            )}

            {tableHeaders.includes('exclude_date') && (
              <View style={styles.cell}>
                <DatePicker
                  disabled={item.changed_status !== ""}
                  withPortal
                  portalId="root-portal-calender"
                  showYearDropdown
                  placeholderText="exclude Date"
                  selected={item.sfsEligibleOffDate ? new Date(item.sfsEligibleOffDate) : null}
                  dateFormat="yyyy-MM-dd"
                  onChange={(event, selectedDate) => handleDateChange(index, 'sfsEligibleOffDate', event, selectedDate)}
                />
              </View>
            )}
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  </ScrollView>
  <TouchableOpacity onPress={updateSkus} style={styles.downloadButton}>
    <Text style={styles.downloadText}>Update SKU's</Text>
  </TouchableOpacity>
  {data.length > itemsPerPage && (
    <View style={styles.paginationFooter}>
      <TouchableOpacity
        style={styles.paginationButton}
        disabled={page === 0}
        onPress={() => setPage(page - 1)}
      >
        <Text>{`< Previous`}</Text>
      </TouchableOpacity>
      <Text>{page + 1} of {numberOfPages}</Text>
      <TouchableOpacity
        style={styles.paginationButton}
        disabled={page === numberOfPages - 1}
        onPress={() => setPage(page + 1)}
      >
        <Text>{`Next >`}</Text>
      </TouchableOpacity>
    </View>
  )}
</View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20
  },
  downloadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  image: {
    width: 20,
    height: 20
  },
  downloadText: {
    marginLeft: 10,
    fontSize: 16
  },
  table: {
    borderWidth: 0.5,
    borderColor: '#ccc',
    backgroundColor: "#fff",
    elevation: 1
  },
  header: {
    flexDirection: 'row',
    backgroundColor: "#fff"
  },
  headerCell: {
    padding: 8,
    borderWidth: 0.5,
    borderColor: '#ccc',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  headerText: {
    fontWeight: 'bold'
  },
  row: {
    flexDirection: 'row'
  },
  cell: {
    padding: 10,
    borderWidth: 0.5,
    borderColor: '#ccc',
    flex: 1
  },
  paginationFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10
  },
  paginationButton: {
    padding: 10
  }
});


export default EditableTable;


