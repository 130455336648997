const defaultKey = 'store_portal_token'

const sessionStorageUtil = {
  setItem: (value, key=defaultKey) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error setting item in sessionStorage:', error);
    }
  },
  getItem: (key=defaultKey) => {
    try {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Error getting item from sessionStorage:', error);
      return null;
    }
  },
  removeItem: (key=defaultKey) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing item from sessionStorage:', error);
    }
  },
};

export default sessionStorageUtil;
